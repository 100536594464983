export default [
  {
    name: "A: 2 ranged weapons & 2 melee weapons",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "bf69bf6b-5429-4419-a04d-0d19b55df4ef",
          modelTypeName: null,
          unitId: "27f7d7fa-79c1-432e-be23-13d80ef86ec6",
        },
      ],
    },
    units: [
      {
        id: "27f7d7fa-79c1-432e-be23-13d80ef86ec6",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Ranged weapon 1",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 4,
                id: "3d091976-d417-4769-b677-f77371767771",
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "d717ab6d-863b-4d8b-adce-6c693c5cb2ac",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Ranged weapon 1",
              },
              {
                name: "Ranged weapon 2",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 1,
                id: "6b4bb6e3-f1b4-420e-8bba-5d6ea989bc41",
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon 2",
                abilities: [],
              },
              {
                name: "Melee weapon 1",
                type: "Melee",
                attacks: 4,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "bcbf96e4-3d11-4422-805c-72f8b4fef263",
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon 1",
                abilities: [],
              },
              {
                name: "Melee weapon 2",
                type: "Melee",
                attacks: 2,
                strength: 5,
                ap: -1,
                damage: 1,
                id: "1d7f0173-1dfb-43bd-a6f1-604c08a7663a",
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon 2",
                abilities: [],
              },
            ],
            id: "bf69bf6b-5429-4419-a04d-0d19b55df4ef",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "bcbf96e4-3d11-4422-805c-72f8b4fef263",
        "1d7f0173-1dfb-43bd-a6f1-604c08a7663a",
      ],
      ranged: [
        "3d091976-d417-4769-b677-f77371767771",
        "6b4bb6e3-f1b4-420e-8bba-5d6ea989bc41",
      ],
    },
  },
  {
    name: "A: 40 bolters @ BS2",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "6f282363-9ebe-4107-a76d-d8ef4f5d6a38",
          modelTypeName: null,
          unitId: "035807c4-dc89-4276-9881-a8be7b019827",
        },
      ],
    },
    units: [
      {
        id: "035807c4-dc89-4276-9881-a8be7b019827",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 40,
                id: "e7af6343-a785-4126-bbb1-602b83511ea0",
                bs: 2,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "f5445cf5-8401-4a16-91cd-66e86c5a24e5",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Bolter",
              },
            ],
            id: "6f282363-9ebe-4107-a76d-d8ef4f5d6a38",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["e7af6343-a785-4126-bbb1-602b83511ea0"],
    },
  },
  {
    name: "A: 20 bolters @ BS3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "c1961467-d08b-4298-b629-02c74855ef76",
          modelTypeName: null,
          unitId: "c69aa570-4ca7-4346-b58b-073351390055",
        },
      ],
    },
    units: [
      {
        id: "c69aa570-4ca7-4346-b58b-073351390055",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: "cac132aa-ca50-483a-8937-8a46a61060dc",
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "23209fc7-17c0-4d63-80b1-2dbda21f9ab8",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Bolter",
              },
            ],
            id: "c1961467-d08b-4298-b629-02c74855ef76",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["cac132aa-ca50-483a-8937-8a46a61060dc"],
    },
  },
  {
    name: "A: 20 bolters @ BS6",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "c3f71870-8340-4120-81b4-aef3defd6d4d",
          modelTypeName: null,
          unitId: "fe5db317-a45f-4188-b2fa-41fcb769704f",
        },
      ],
    },
    units: [
      {
        id: "fe5db317-a45f-4188-b2fa-41fcb769704f",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: "a3c0e72b-2105-4d1a-ac5a-563509640ed1",
                bs: 6,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "2e7a30dd-5f45-4fbf-a7de-e965aadf2bd6",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Bolter",
              },
            ],
            id: "c3f71870-8340-4120-81b4-aef3defd6d4d",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["a3c0e72b-2105-4d1a-ac5a-563509640ed1"],
    },
  },
  {
    name: "A: 10 bolters @ BS3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      points: 150,
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "84d6848e-0cdb-4eb4-b6b1-cd9683f0fd87",
          modelTypeName: null,
          unitId: "92f9a8dc-494f-41d4-b907-0aa581e3b101",
        },
      ],
    },
    units: [
      {
        id: "92f9a8dc-494f-41d4-b907-0aa581e3b101",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolter",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "462e9984-8a24-439c-8916-5d9e2fb34ce7",
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "22b9f522-08c1-4477-93bf-577d13423210",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Bolter",
              },
            ],
            id: "84d6848e-0cdb-4eb4-b6b1-cd9683f0fd87",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: 150,
        abilities: [],
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["462e9984-8a24-439c-8916-5d9e2fb34ce7"],
    },
  },
  {
    name: "A: 10 heavy bolters @ BS3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      points: 320,
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "630d634c-6b41-4010-943c-11bfe8717464",
          modelTypeName: null,
          unitId: "4ccdf136-c21e-490a-a34c-6f97a8b73e62",
        },
      ],
    },
    units: [
      {
        id: "4ccdf136-c21e-490a-a34c-6f97a8b73e62",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Heavy bolter",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: -1,
                damage: 2,
                countPerUnit: 10,
                id: "20598fa1-a2eb-4fe6-ae7a-f8a6dbd5a064",
                bs: 3,
                ws: null,
                nameUnique: "Heavy bolter",
                abilities: [],
              },
            ],
            id: "630d634c-6b41-4010-943c-11bfe8717464",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: 320,
        abilities: [],
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["20598fa1-a2eb-4fe6-ae7a-f8a6dbd5a064"],
    },
  },
  {
    name: "A: 10 3 shot Strength 3 guns @ BS3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "0ee1feb1-54ff-4657-94c1-f84eee3fa6fa",
          modelTypeName: null,
          unitId: "1b2ea4c5-ea57-4655-97e5-f3ec3db61d75",
        },
      ],
    },
    units: [
      {
        id: "1b2ea4c5-ea57-4655-97e5-f3ec3db61d75",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "7d95b296-1219-4397-9f57-41280a07904c",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
                abilities: [],
              },
            ],
            id: "0ee1feb1-54ff-4657-94c1-f84eee3fa6fa",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["7d95b296-1219-4397-9f57-41280a07904c"],
    },
  },
  {
    name: "A: Weapon ability: ANTI AIR",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "fc814d38-50d5-4851-9508-46aea1387168",
          modelTypeName: null,
          unitId: "ad47c2d7-df17-4bbe-874d-cdd8cbdde974",
        },
      ],
    },
    units: [
      {
        id: "ad47c2d7-df17-4bbe-874d-cdd8cbdde974",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Weapon with ANTI AIR",
                type: "Ranged",
                attacks: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                abilities: [
                  {
                    description: "+1 to hit roll (AIRCRAFT only)",
                    id: "d5bd4255-6913-4efb-b448-c3211cfccdc3",
                    conditions: [
                      {
                        data: {
                          keywords: ["AIRCRAFT"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: 1,
                      },
                      text: "Modify (relative)",
                      type: "modifyRelative",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 5,
                id: "633fd727-cec2-4426-a25d-3c728324be6a",
                bs: 3,
                ws: null,
                nameUnique: "Weapon with ANTI AIR",
              },
              {
                name: "Weapon with ANTI AIR:LEGACY",
                type: "Ranged",
                attacks: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                abilities: [
                  {
                    description: "+1 to hit roll (FLY only)",
                    id: "3da50691-8c1a-490a-a38e-c95b4edcd4a3",
                    conditions: [
                      {
                        data: {
                          keywords: ["FLY"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: 1,
                      },
                      text: "Modify (relative)",
                      type: "modifyRelative",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "-1 to hit roll (excludes FLY)",
                    id: "5583263c-6af5-4ca7-8d70-d44f5a8f0cb9",
                    conditions: [
                      {
                        data: {
                          keywords: ["FLY"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        operator: {
                          title: "Subtract",
                          key: "subtract",
                        },
                        relativeValue: 1,
                      },
                      text: "Modify (relative)",
                      type: "modifyRelative",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 5,
                id: "dfd2ca37-c31a-4a8d-bae7-63c6f8f5f855",
                bs: 3,
                ws: null,
                nameUnique: "Weapon with ANTI AIR:LEGACY",
              },
            ],
            id: "fc814d38-50d5-4851-9508-46aea1387168",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "633fd727-cec2-4426-a25d-3c728324be6a",
        "dfd2ca37-c31a-4a8d-bae7-63c6f8f5f855",
      ],
    },
  },
  {
    name: "A: Arc pistol x10",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "0dca1f10-7ef4-4059-81c3-649dad4b6d80",
          modelTypeName: null,
          unitId: "0bcece75-8c79-4f2a-add8-8dd7fba04419",
        },
      ],
    },
    units: [
      {
        id: "0bcece75-8c79-4f2a-add8-8dd7fba04419",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Arc pistol",
                type: "Ranged",
                attacks: 1,
                strength: 5,
                ap: -2,
                damage: 1,
                abilities: [
                  {
                    description: "Set damage to 2 (VEHICLE only)",
                    id: "213184f0-3c81-4743-88a4-aa4dc44ee9e1",
                    conditions: [
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: 2,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (VEHICLE only)",
                    id: "e449f939-5dcc-40a4-a1a6-a3dfc03ab333",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "b42ff10e-5b04-4165-8b89-a3cd82c99796",
                bs: 3,
                ws: null,
                nameUnique: "Arc pistol",
              },
            ],
            id: "0dca1f10-7ef4-4059-81c3-649dad4b6d80",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["b42ff10e-5b04-4165-8b89-a3cd82c99796"],
    },
  },
  {
    name: "A: Arc rifle x10",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "b7c35e68-fa58-410c-9ddf-4e05270ce68f",
          modelTypeName: null,
          unitId: "e1a556b2-9c95-407e-9e46-176ae5bcecdc",
        },
      ],
    },
    units: [
      {
        id: "e1a556b2-9c95-407e-9e46-176ae5bcecdc",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Arc rifle",
                type: "Ranged",
                attacks: 1,
                strength: 6,
                ap: -2,
                damage: "D3",
                abilities: [
                  {
                    description: "Set damage to 3 (VEHICLE only)",
                    id: "9fd21c4f-86b2-4825-a5aa-a1510c3b73d6",
                    conditions: [
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: 3,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (VEHICLE only)",
                    id: "963296fc-c53c-45a2-ae4b-5dfb0ead4c65",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "e7bc0390-66a9-4ff6-ba5a-591e39d281fb",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "b7dc1b5d-8ffb-44b1-ba51-dee23be81a41",
                bs: 3,
                ws: null,
                nameUnique: "Arc rifle",
              },
            ],
            id: "b7c35e68-fa58-410c-9ddf-4e05270ce68f",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["b7dc1b5d-8ffb-44b1-ba51-dee23be81a41"],
    },
  },
  {
    name: "A: Weapon ability: Always hit",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "bd1ba14f-8a25-4dab-8673-69c6a41ebabc",
          modelTypeName: null,
          unitId: "450a6799-6d91-455f-aea6-8f8c003b0c3c",
        },
      ],
    },
    units: [
      {
        id: "450a6799-6d91-455f-aea6-8f8c003b0c3c",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Weapon with ability",
                type: "Ranged",
                attacks: "D6",
                strength: 4,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    description: "Always hit",
                    id: "b2c3589f-e370-4985-af43-4329a4209a6d",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Hit",
                          key: "hit",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Torrent",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 5,
                id: "8aa426cd-bd6a-4909-b993-d9c678f30225",
                bs: null,
                ws: null,
                nameUnique: "Weapon with ability",
              },
            ],
            id: "bd1ba14f-8a25-4dab-8673-69c6a41ebabc",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["8aa426cd-bd6a-4909-b993-d9c678f30225"],
    },
  },
  {
    name: "A: Weapon ability: Blast",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "346c3a21-6ad2-4433-9600-23101b40e609",
          modelTypeName: null,
          unitId: "14275640-275f-4f89-94ed-c70340b57174",
        },
      ],
    },
    units: [
      {
        id: "14275640-275f-4f89-94ed-c70340b57174",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Ranged weapon with Blast",
                type: "Ranged",
                attacks: "D3+3",
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Blast",
                    id: "bdbff9b0-51a8-4494-afb0-00d1dbf91750",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        option: {
                          title: "Blast",
                          key: "blast",
                        },
                      },
                      text: "Special",
                      type: "special",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Blast",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 1,
                id: "5cc725c3-65f6-4029-bd57-8ac530d66b7b",
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Blast",
              },
              {
                name: "Melee weapon with Blast",
                type: "Melee",
                attacks: "D3+3",
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Blast",
                    id: "1485579f-8466-4425-b87b-c6cd06f353c1",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        option: {
                          title: "Blast",
                          key: "blast",
                        },
                      },
                      text: "Special",
                      type: "special",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Blast",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 1,
                id: "d5f33b40-ab07-4096-b093-ecc5046d8918",
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon with Blast",
              },
            ],
            id: "346c3a21-6ad2-4433-9600-23101b40e609",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["d5f33b40-ab07-4096-b093-ecc5046d8918"],
      ranged: ["5cc725c3-65f6-4029-bd57-8ac530d66b7b"],
    },
  },
  {
    name: "A: Weapon ability: Grav",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "06ed04e1-8a8c-40b9-9c9e-447ad0597b49",
          modelTypeName: null,
          unitId: "922e2e63-051d-40a5-b54e-0b7745c0501a",
        },
      ],
    },
    units: [
      {
        id: "922e2e63-051d-40a5-b54e-0b7745c0501a",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Melee weapon with Grav: D3",
                type: "Melee",
                attacks: 25,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to D3",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "D3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                    id: "d70318f2-6f57-432f-93ab-84d7e9993ba7",
                  },
                ],
                id: "b34d1cf8-39a6-4c25-8bf2-d67b95907c56",
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Melee weapon with Grav: D3",
              },
              {
                name: "Ranged weapon with Grav: D3",
                type: "Ranged",
                attacks: 5,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to D3",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "D3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                    id: "9e992c77-ecd9-401b-9169-2cc046cf86e3",
                  },
                ],
                countPerUnit: 5,
                id: "e5858c17-143e-44f1-a096-a61dea0bc42b",
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Grav: D3",
              },
              {
                name: "Ranged weapon with Grav: 2",
                type: "Ranged",
                attacks: 5,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to 2",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "2",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                    id: "45aa3163-7239-4310-92a6-1397ad324e4f",
                  },
                ],
                countPerUnit: 5,
                id: "bfb3b3c1-d3d6-481c-9c26-fa2234d8bbe5",
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Grav: 2",
              },
              {
                name: "Ranged weapon with Grav: 3",
                type: "Ranged",
                attacks: 5,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          defenderChar: {
                            title: "Save",
                            key: "save",
                          },
                          defenderCharValue: "4",
                          defenderCharState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          comparison: {
                            title: "Less than",
                            key: "lessThan",
                          },
                        },
                        preselected: false,
                        text: "Defender characteristic",
                        type: "defenderCharacteristic",
                        required: true,
                      },
                    ],
                    description:
                      "Save (unmodified) is less than 4 : set damage to 3",
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                    id: "27221354-5b2e-4aa7-829c-70a5e7989a24",
                  },
                ],
                countPerUnit: 5,
                id: "ad65b2d9-1e41-44bc-8ebd-c8b8560089d9",
                bs: 3,
                ws: null,
                nameUnique: "Ranged weapon with Grav: 3",
              },
            ],
            id: "06ed04e1-8a8c-40b9-9c9e-447ad0597b49",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["b34d1cf8-39a6-4c25-8bf2-d67b95907c56"],
      ranged: [
        "e5858c17-143e-44f1-a096-a61dea0bc42b",
        "bfb3b3c1-d3d6-481c-9c26-fa2234d8bbe5",
        "ad65b2d9-1e41-44bc-8ebd-c8b8560089d9",
      ],
    },
  },
  {
    name: "A: Weapon ability: Melta",
    abilitiesSelected: [],
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      points: null,
      roles: ["Attacker: Ranged"],
      wounds: null,
      toughness: 0,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "d3ccea85-e8f4-4b47-8250-551d764a6da4",
          modelTypeName: null,
          unitId: "ac2c7f74-af16-408d-9c3c-9a21b193da7a",
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: "ac2c7f74-af16-408d-9c3c-9a21b193da7a",
        leader: false,
        modelTypes: [
          {
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Ranged weapon with MELTA 2",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 8,
                ap: -4,
                damage: "D6",
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Melta 2",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "+2 to damage (within half range)",
                    effect: {
                      text: "Modify (relative)",
                      type: "modifyRelative",
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: 2,
                      },
                    },
                    scope: ["weapon"],
                    id: "cdacb940-2c67-43b9-b81a-736a0b9bc3e5",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "d7732fec-fae8-4e28-b09b-daeef252967c",
                ws: null,
                nameUnique: "Ranged weapon with MELTA 2",
              },
              {
                name: "Ranged weapon with MELTA D3",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 8,
                ap: -4,
                damage: "D6",
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Melta D3",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "+D3 to damage (within half range)",
                    effect: {
                      text: "Modify (relative)",
                      type: "modifyRelative",
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Add",
                          key: "add",
                        },
                        relativeValue: "D3",
                      },
                    },
                    scope: ["weapon"],
                    id: "c232f441-664a-422a-a032-d74f1207d7be",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "43a3eefa-60c1-4b81-ab0a-3d7b0c05f91f",
                ws: null,
                nameUnique: "Ranged weapon with MELTA D3",
              },
            ],
            wounds: null,
            id: "d3ccea85-e8f4-4b47-8250-551d764a6da4",
            name: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "d7732fec-fae8-4e28-b09b-daeef252967c",
        "43a3eefa-60c1-4b81-ab0a-3d7b0c05f91f",
      ],
    },
  },
  {
    name: "A: Weapon ability: POISON",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "f78abe52-f824-4c63-afc0-f4bf90037967",
          modelTypeName: null,
          unitId: "34de744e-b52e-4a80-9a05-4bc70efc3396",
        },
      ],
    },
    units: [
      {
        id: "34de744e-b52e-4a80-9a05-4bc70efc3396",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "61d2474d-8ff8-493b-977c-dfd864abc310",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Legacy POISON weapon with strength '*'",
                type: "Ranged",
                attacks: 1,
                strength: "*",
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: "ee9569bf-a11c-483d-9fdb-16153c811781",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "c3055cfb-7a7d-414c-84df-cc9a795ea142",
                bs: 3,
                ws: null,
                nameUnique: "Legacy POISON weapon with strength '*'",
              },
              {
                name: "Baseline with POISON:4",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 4+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: "c054f7b3-07c5-49e8-8e6b-63091e703290",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "c1d03d10-56fa-4426-b253-a597df6fb003",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with POISON:4",
              },
              {
                name: "Baseline with POISON:3",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 3+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: "a0fb3665-8317-4962-a729-24defa9622b3",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "3",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "85d592a0-0bf6-4cc4-b673-57fd293b326a",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with POISON:3",
              },
              {
                name: "Baseline with POISON:2",
                type: "Ranged",
                attacks: 1,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a 2+ (unmodified) (excludes VEHICLE, TITANIC)",
                    id: "264a2300-b6fc-462d-8924-5975e08af829",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "2",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                      },
                      {
                        data: {
                          keywords: ["VEHICLE", "TITANIC"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    scope: ["weapon"],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "2cc33f7a-6b6c-4d88-aa63-d8beaea7f89c",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with POISON:2",
              },
            ],
            id: "f78abe52-f824-4c63-afc0-f4bf90037967",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "61d2474d-8ff8-493b-977c-dfd864abc310",
        "c3055cfb-7a7d-414c-84df-cc9a795ea142",
        "c1d03d10-56fa-4426-b253-a597df6fb003",
        "85d592a0-0bf6-4cc4-b673-57fd293b326a",
        "2cc33f7a-6b6c-4d88-aa63-d8beaea7f89c",
      ],
    },
  },
  {
    name: "A: Weapon ability: PLAGUE WEAPON",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "af04ff11-7dc6-4e8f-913c-c0751e803fd9",
          modelTypeName: null,
          unitId: "aa2a3744-f087-4af2-8263-f6103498e05a",
        },
      ],
    },
    units: [
      {
        id: "aa2a3744-f087-4af2-8263-f6103498e05a",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 10,
                strength: 6,
                ap: -2,
                damage: 1,
                countPerUnit: 4,
                id: "ee2f4b5b-3449-48d3-a3e3-e68556ceed1a",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with PLAGUE WEAPON",
                type: "Ranged",
                attacks: 10,
                strength: 6,
                ap: -2,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll all wound roll results of 1",
                    id: "86fcb375-8f5b-4d3d-9e04-ab1d142e387c",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: 1,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 4,
                id: "f9f591cb-a43c-47f8-9530-8a6fccbd6570",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with PLAGUE WEAPON",
              },
            ],
            id: "af04ff11-7dc6-4e8f-913c-c0751e803fd9",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "ee2f4b5b-3449-48d3-a3e3-e68556ceed1a",
        "f9f591cb-a43c-47f8-9530-8a6fccbd6570",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=AP:-3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "97e675a5-d482-4c14-9f55-64b251cb8391",
          modelTypeName: null,
          unitId: "f8ef051d-a3e3-4a70-a4e8-c2b06b46822d",
        },
      ],
    },
    units: [
      {
        id: "f8ef051d-a3e3-4a70-a4e8-c2b06b46822d",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: "1b3c78be-ce6e-451f-a27e-43ff74997188",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set AP to -3",
                    id: "8353ea93-b7bc-4eab-93e3-de7031d3f350",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "AP",
                          key: "ap",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: -3,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 20,
                id: "e5afef3b-9c8a-426b-95f1-4bd2b2a2b91b",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: "97e675a5-d482-4c14-9f55-64b251cb8391",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "1b3c78be-ce6e-451f-a27e-43ff74997188",
        "e5afef3b-9c8a-426b-95f1-4bd2b2a2b91b",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=AP:-4",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "a6decf6f-b293-41cf-a551-7143d9b6bea0",
          modelTypeName: null,
          unitId: "0c2c76e7-46b9-44d2-b3dc-d1a90b1509cd",
        },
      ],
    },
    units: [
      {
        id: "0c2c76e7-46b9-44d2-b3dc-d1a90b1509cd",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: "ce8b2f13-a500-4ef2-abee-3645428de520",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set AP to -4",
                    id: "550a06de-3865-4b18-a96f-d85066c2d94e",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "AP",
                          key: "ap",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: -4,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 20,
                id: "293bae4a-9ce4-48bb-8ed5-cfbce7bc07d9",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: "a6decf6f-b293-41cf-a551-7143d9b6bea0",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "ce8b2f13-a500-4ef2-abee-3645428de520",
        "293bae4a-9ce4-48bb-8ed5-cfbce7bc07d9",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=AP:-6",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "372aab99-4eba-4428-9856-306049338683",
          modelTypeName: null,
          unitId: "0e2469a9-36ef-488f-952f-49ff4511c0ee",
        },
      ],
    },
    units: [
      {
        id: "0e2469a9-36ef-488f-952f-49ff4511c0ee",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: "809b7cff-7b9b-4fe6-ba2e-98c346432f58",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set AP to -6",
                    id: "648ed621-31c5-4640-b3a1-a2eca082bd34",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "AP",
                          key: "ap",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: -6,
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 20,
                id: "f4957841-f92b-4612-8b79-358b63076815",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: "372aab99-4eba-4428-9856-306049338683",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "809b7cff-7b9b-4fe6-ba2e-98c346432f58",
        "f4957841-f92b-4612-8b79-358b63076815",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=+1MW",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "07c49d32-42d8-4df1-98dc-9a5293296321",
          modelTypeName: null,
          unitId: "a03ddbdd-66ab-4f78-aebd-7927293922fa",
        },
      ],
    },
    units: [
      {
        id: "a03ddbdd-66ab-4f78-aebd-7927293922fa",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "99f5070c-0b1f-49dc-8f3d-24741fbc1b7f",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with WOUND:6+=+1MW",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : 1 mortal wound",
                    id: "142cc9cf-7c2c-47a4-acd3-e0bb671e0f4a",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: 1,
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "e333a449-14ae-4432-982f-efd6f2c8cbfb",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6+=+1MW",
              },
            ],
            id: "07c49d32-42d8-4df1-98dc-9a5293296321",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "99f5070c-0b1f-49dc-8f3d-24741fbc1b7f",
        "e333a449-14ae-4432-982f-efd6f2c8cbfb",
      ],
    },
  },
  {
    name: "A: Weapon ability: HIT:6=+2HITS",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "e86acf58-401b-4bba-9f0b-65852d11e51c",
          modelTypeName: null,
          unitId: "6d9b5bfb-26da-44b0-9834-3dced23d0508",
        },
      ],
    },
    units: [
      {
        id: "6d9b5bfb-26da-44b0-9834-3dced23d0508",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                countPerUnit: 5,
                id: "f8be8a98-7414-46a9-9786-e7eabc20a79c",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Hit roll of 6 (unmodified) : 2 extra hits",
                    id: "857a06a2-d3b6-4612-81fa-d7df1710b86a",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: "2",
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 5,
                id: "e0c67c35-c40a-4fa8-ae99-53cfaa89c9a6",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: "e86acf58-401b-4bba-9f0b-65852d11e51c",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "f8be8a98-7414-46a9-9786-e7eabc20a79c",
        "e0c67c35-c40a-4fa8-ae99-53cfaa89c9a6",
      ],
    },
  },
  {
    name: "A: Weapon ability: HIT:6+=+2HITS",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "7e028ac7-7f1e-45fa-b278-c71783830904",
          modelTypeName: null,
          unitId: "8e4425de-d832-4dab-b230-3c912ab7c006",
        },
      ],
    },
    units: [
      {
        id: "8e4425de-d832-4dab-b230-3c912ab7c006",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                countPerUnit: 5,
                id: "6e318134-f6a9-41dc-bc7e-05519162adcf",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with weapon ability",
                type: "Ranged",
                attacks: 3,
                strength: 5,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Hit roll of 6+ : 2 extra hits",
                    id: "23cf2f54-b7e2-4319-a50a-219e834ea6af",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: "2",
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 5,
                id: "93962eec-67f1-4055-9afe-7019726861b7",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with weapon ability",
              },
            ],
            id: "7e028ac7-7f1e-45fa-b278-c71783830904",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "6e318134-f6a9-41dc-bc7e-05519162adcf",
        "93962eec-67f1-4055-9afe-7019726861b7",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6=DAMAGE:2",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "f493c7c1-a201-4fa6-9a77-0fde6ecf0fdb",
          modelTypeName: null,
          unitId: "8188a1b6-ab8b-4ffc-b79f-81dacae0818c",
        },
      ],
    },
    units: [
      {
        id: "8188a1b6-ab8b-4ffc-b79f-81dacae0818c",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 20,
                id: "ee6bea78-fd79-49de-942c-df419a1ae64b",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with WOUND:6=DAMAGE:2",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : set damage to 2",
                    id: "e07acb31-50a6-47a4-b0ff-b58e6a291a3a",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "2",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 20,
                id: "88b1d0a9-18d6-4c17-aa2d-5f8c4056fe85",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6=DAMAGE:2",
              },
            ],
            id: "f493c7c1-a201-4fa6-9a77-0fde6ecf0fdb",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "ee6bea78-fd79-49de-942c-df419a1ae64b",
        "88b1d0a9-18d6-4c17-aa2d-5f8c4056fe85",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6+=DAMAGE:N",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "b6d73a99-f54b-4d2a-a0eb-cbdc50f2d284",
          modelTypeName: null,
          unitId: "4e64de13-7103-4ae8-a4ef-87a169e84ed8",
        },
      ],
    },
    units: [
      {
        id: "4e64de13-7103-4ae8-a4ef-87a169e84ed8",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "6f899f76-6829-4332-b443-8ba4d64a125f",
                bs: 2,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with WOUND:6+=DAMAGE:2",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set damage to 2",
                    id: "7354dcc2-dad7-4463-85ad-a4ca34fc4643",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "2",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "498778bb-1636-48b0-bfd2-adb84cc62630",
                bs: 2,
                ws: null,
                nameUnique: "Baseline with WOUND:6+=DAMAGE:2",
              },
              {
                name: "Baseline with WOUND:6+=DAMAGE:3",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Wound roll of 6+ : set damage to 3",
                    id: "8b21b7b3-8954-4055-b9c9-c62eeca92a67",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Modified",
                            key: "modified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        operator: {
                          title: "Replace",
                          key: "replace",
                        },
                        absoluteValue: "3",
                      },
                      text: "Modify (absolute)",
                      type: "modifyAbsolute",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "74fb2746-f530-43ec-9582-48bd518d2f66",
                bs: 2,
                ws: null,
                nameUnique: "Baseline with WOUND:6+=DAMAGE:3",
              },
            ],
            id: "b6d73a99-f54b-4d2a-a0eb-cbdc50f2d284",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "6f899f76-6829-4332-b443-8ba4d64a125f",
        "498778bb-1636-48b0-bfd2-adb84cc62630",
        "74fb2746-f530-43ec-9582-48bd518d2f66",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6=+1MW",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "eea0802d-5484-47d7-b4cf-626ebefeaeeb",
          modelTypeName: null,
          unitId: "1a92566c-2360-4993-be32-bf990048ddd8",
        },
      ],
    },
    units: [
      {
        id: "1a92566c-2360-4993-be32-bf990048ddd8",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "4b198781-1a2b-429e-b932-b90e2d361f46",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with WOUND:6=+1MW",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : 1 mortal wound",
                    id: "d04998cb-ab72-482d-a9f5-85f07c00323d",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: 1,
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "03158638-b75c-4a69-84b1-34da7f8db3f7",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6=+1MW",
              },
            ],
            id: "eea0802d-5484-47d7-b4cf-626ebefeaeeb",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "4b198781-1a2b-429e-b932-b90e2d361f46",
        "03158638-b75c-4a69-84b1-34da7f8db3f7",
      ],
    },
  },
  {
    name: "A: Weapon ability: WOUND:6=+2MW",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "1aef5385-ede1-4e1a-a6a2-8265bb64a4dd",
          modelTypeName: null,
          unitId: "db069567-3550-44e6-9962-b3e939ef83c1",
        },
      ],
    },
    units: [
      {
        id: "db069567-3550-44e6-9962-b3e939ef83c1",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "b7203339-2c0c-4998-b725-731a7ddfcb7a",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with WOUND:6=+2MW",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : 2 mortal wounds",
                    id: "e6f0851e-32d3-4f37-ad9c-143dd634b6a6",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: "2",
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "052ddcbf-e0dc-4cab-8741-2443c4845082",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with WOUND:6=+2MW",
              },
            ],
            id: "1aef5385-ede1-4e1a-a6a2-8265bb64a4dd",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "b7203339-2c0c-4998-b725-731a7ddfcb7a",
        "052ddcbf-e0dc-4cab-8741-2443c4845082",
      ],
    },
  },
  {
    name: "A: Weapon type: Rapid Fire",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "796892c2-c628-4a5d-becb-a006dadaf0a2",
          modelTypeName: null,
          unitId: "673fc14e-72f4-4763-b5c4-71a744c7ea79",
        },
      ],
    },
    units: [
      {
        id: "673fc14e-72f4-4763-b5c4-71a744c7ea79",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolt rifle",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: -1,
                damage: 1,
                countPerUnit: 5,
                id: "b6b929f9-4dea-42f2-b448-1048ab00028e",
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: "0673db0a-4b4e-46e5-895a-f39c5c8102d6",
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Bolt rifle",
              },
            ],
            id: "796892c2-c628-4a5d-becb-a006dadaf0a2",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["b6b929f9-4dea-42f2-b448-1048ab00028e"],
    },
  },
  {
    name: "A: 9 flechette carbines @ BS3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "873ab262-23e7-417b-951b-f2c8219d90a9",
          modelTypeName: null,
          unitId: "96f20a08-70a6-4974-b74c-53291404fc31",
        },
      ],
    },
    units: [
      {
        id: "96f20a08-70a6-4974-b74c-53291404fc31",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 19,
                strength: 3,
                ap: 0,
                damage: 1,
                id: "91cb271f-dd11-4109-b598-4532860d833f",
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
              {
                name: "Flechette carbine",
                type: "Ranged",
                attacks: 5,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 9,
                id: "305a62f9-28a6-4886-a9ee-e1ab9de8810d",
                bs: 3,
                ws: null,
                nameUnique: "Flechette carbine",
                abilities: [],
              },
            ],
            id: "873ab262-23e7-417b-951b-f2c8219d90a9",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["91cb271f-dd11-4109-b598-4532860d833f"],
      ranged: ["305a62f9-28a6-4886-a9ee-e1ab9de8810d"],
    },
  },
  {
    name: "A: 10 3 damage guns @ BS3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "72239e46-95b5-4ee1-8bb4-381823fd23a9",
          modelTypeName: null,
          unitId: "dc63d656-0115-48ba-a4c7-fdedf166574d",
        },
      ],
    },
    units: [
      {
        id: "dc63d656-0115-48ba-a4c7-fdedf166574d",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Damage 3 gun",
                type: "Ranged",
                attacks: 2,
                strength: 6,
                ap: -3,
                damage: 3,
                countPerUnit: 10,
                id: "ce013fd9-ecba-4e9f-89c8-072d93f9f9a0",
                bs: 3,
                ws: null,
                nameUnique: "Damage 3 gun",
                abilities: [],
              },
            ],
            id: "72239e46-95b5-4ee1-8bb4-381823fd23a9",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["ce013fd9-ecba-4e9f-89c8-072d93f9f9a0"],
    },
  },
  {
    name: "A: Weapon ability: DENY INVULN",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "3ffeb207-c4c3-47d0-a095-629316f78672",
          modelTypeName: null,
          unitId: "4ba85bdf-63d8-40f1-a86b-fbc5d49fb485",
        },
      ],
    },
    units: [
      {
        id: "4ba85bdf-63d8-40f1-a86b-fbc5d49fb485",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "ad7ce922-ae10-49f0-9aa8-15754d3e8fbb",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with DENY INVULN",
                type: "Ranged",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Disable invuln",
                    id: "bdfcd532-92ab-4cfb-8d1f-6360e0d1d0e3",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        option: {
                          title: "Disable invuln",
                          key: "disableInvuln",
                        },
                      },
                      text: "Disable ability",
                      type: "disableAbility",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "a3da1bd9-8fe4-4c5a-883c-f26a1f018685",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with DENY INVULN",
              },
            ],
            id: "3ffeb207-c4c3-47d0-a095-629316f78672",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "ad7ce922-ae10-49f0-9aa8-15754d3e8fbb",
        "a3da1bd9-8fe4-4c5a-883c-f26a1f018685",
      ],
    },
  },
  {
    name: "A: MWs on wounds of 6",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "9f79bde3-7110-4348-9870-b515a1f2297c",
          modelTypeName: null,
          unitId: "2ec25ff1-bdda-4da8-a99d-0d0e997beb13",
        },
      ],
    },
    units: [
      {
        id: "2ec25ff1-bdda-4da8-a99d-0d0e997beb13",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "High volume - no MWs",
                type: "Ranged",
                attacks: 6,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "0f3ce3d8-4cdd-479e-bfb0-38d4b2088323",
                bs: 3,
                ws: null,
                nameUnique: "High volume - no MWs",
                abilities: [],
              },
              {
                name: "High volume - MWs on 6s",
                type: "Ranged",
                attacks: 6,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : 1 mortal wound",
                    id: "23e4dd3e-bc0d-4219-8c39-cbede04c8846",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: 1,
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "90cdd748-1aa8-4bc9-aebe-93d35cea0c5d",
                bs: 3,
                ws: null,
                nameUnique: "High volume - MWs on 6s",
              },
            ],
            id: "9f79bde3-7110-4348-9870-b515a1f2297c",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "0f3ce3d8-4cdd-479e-bfb0-38d4b2088323",
        "90cdd748-1aa8-4bc9-aebe-93d35cea0c5d",
      ],
    },
  },
  {
    name: "A: Weapon ability: HIT:6=AUTO WOUND",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "8044fdd7-ac88-4a07-ad70-20d26b0895e8",
          modelTypeName: null,
          unitId: "6c6d35e0-a395-4a0a-a168-8395d5bd67d6",
        },
      ],
    },
    units: [
      {
        id: "6c6d35e0-a395-4a0a-a168-8395d5bd67d6",
        leader: false,
        modelTypes: [
          {
            invuln: 6,
            modelCount: 10,
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "feaf8383-eeb5-4ea0-bc62-bdcf361e9c35",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with HIT:6=AUTO WOUND",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Always wound on a hit roll of 6 (unmodified)",
                    id: "96f8a8f0-7444-45d0-849f-b4dd9560d670",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "9b640f94-f21c-4254-bcb6-fb388f9f6328",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with HIT:6=AUTO WOUND",
              },
            ],
            wounds: 1,
            id: "8044fdd7-ac88-4a07-ad70-20d26b0895e8",
            name: null,
            ignore: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "feaf8383-eeb5-4ea0-bc62-bdcf361e9c35",
        "9b640f94-f21c-4254-bcb6-fb388f9f6328",
      ],
    },
  },
  {
    name: "A: Weapon ability: HIT:6=AUTO WOUND:!VEHICLE",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "49587880-eca3-42ef-8cae-85698ac6fc6b",
          modelTypeName: null,
          unitId: "f531899b-81b9-4037-838a-4bfb14060587",
        },
      ],
    },
    units: [
      {
        id: "f531899b-81b9-4037-838a-4bfb14060587",
        leader: false,
        modelTypes: [
          {
            invuln: 6,
            modelCount: 10,
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Baseline",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                countPerUnit: 10,
                id: "ea6a3f9b-ec65-4b42-83f8-616eddee7acc",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Baseline with HIT:6=AUTO WOUND:!VEHICLE",
                type: "Ranged",
                attacks: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Always wound on a hit roll of 6 (unmodified) (excludes VEHICLE)",
                    id: "50ca3c56-5f19-4744-8a70-515ae8dc4be7",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                      {
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        preselected: false,
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                      },
                    ],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    scope: ["weapon"],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                countPerUnit: 10,
                id: "68d0b1fb-6bf6-4217-893f-fccde664b558",
                bs: 3,
                ws: null,
                nameUnique: "Baseline with HIT:6=AUTO WOUND:!VEHICLE",
              },
            ],
            wounds: 1,
            id: "49587880-eca3-42ef-8cae-85698ac6fc6b",
            name: null,
            ignore: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "ea6a3f9b-ec65-4b42-83f8-616eddee7acc",
        "68d0b1fb-6bf6-4217-893f-fccde664b558",
      ],
    },
  },
  {
    name: "A: Melee - taser weapons",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "573b9cd4-a69f-40c7-9f30-9f7cddab50eb",
          modelTypeName: null,
          unitId: "d9d1ab82-8521-4b91-b07f-fa72beeef8b1",
        },
      ],
    },
    units: [
      {
        id: "d9d1ab82-8521-4b91-b07f-fa72beeef8b1",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Taser goad",
                type: "Melee",
                attacks: 11,
                strength: 6,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    description: "Hit roll of 6 (unmodified) : 2 extra hits",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: "2",
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    id: "5f1cb2a9-331c-4490-a755-a6ce21ebed8e",
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "429b5f02-7987-40f7-8e84-f1985a8ffa6d",
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Taser goad",
              },
            ],
            id: "573b9cd4-a69f-40c7-9f30-9f7cddab50eb",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["429b5f02-7987-40f7-8e84-f1985a8ffa6d"],
      ranged: [],
    },
  },
  {
    name: "A: Laser chickens x3",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "da0cc1c1-8a5c-4f78-9bc7-b378b367c27e",
          modelTypeName: null,
          unitId: "6f84af37-f836-4f8f-af88-8470cd0429be",
        },
      ],
    },
    units: [
      {
        id: "6f84af37-f836-4f8f-af88-8470cd0429be",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Twin cognis lascannon",
                countPerUnit: "3",
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                id: "2ba970d1-e43a-444b-8343-be358f640bed",
                bs: 3,
                ws: null,
                nameUnique: "Twin cognis lascannon",
                abilities: [],
              },
            ],
            id: "da0cc1c1-8a5c-4f78-9bc7-b378b367c27e",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["2ba970d1-e43a-444b-8343-be358f640bed"],
    },
  },
  {
    name: "D: T3, W2, 4+, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "becfe536-5fd5-4699-98f6-fb400718e509",
          modelTypeName: null,
          unitId: "10b2d296-bc3f-49ee-8898-8b10e4adf3aa",
        },
      ],
    },
    units: [
      {
        id: "10b2d296-bc3f-49ee-8898-8b10e4adf3aa",
        leader: false,
        modelTypes: [
          {
            modelCount: 20,
            save: 4,
            toughness: 3,
            wounds: 2,
            id: "becfe536-5fd5-4699-98f6-fb400718e509",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 4+, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      points: 160,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "ad7ccbb5-252c-4d71-9352-5255076adaab",
          modelTypeName: null,
          unitId: "34061330-1413-45d6-94b2-2cfd8c005416",
        },
      ],
    },
    units: [
      {
        id: "34061330-1413-45d6-94b2-2cfd8c005416",
        leader: false,
        modelTypes: [
          {
            modelCount: 20,
            save: 4,
            toughness: 4,
            wounds: 2,
            id: "ad7ccbb5-252c-4d71-9352-5255076adaab",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: 160,
        abilities: [],
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 4+, 4++, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "3e94626f-12dd-4fdd-9e31-c3c7622dbb87",
          modelTypeName: null,
          unitId: "6c49f11d-6c3e-472f-8c51-3edd947e0d1f",
        },
      ],
    },
    units: [
      {
        id: "6c49f11d-6c3e-472f-8c51-3edd947e0d1f",
        leader: false,
        modelTypes: [
          {
            invuln: 4,
            modelCount: 20,
            save: 4,
            toughness: 4,
            wounds: 2,
            id: "3e94626f-12dd-4fdd-9e31-c3c7622dbb87",
            name: null,
            weapons: [],
            ignore: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 3+, 5++, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "06bdddbd-713d-4367-8517-4605a564162c",
          modelTypeName: null,
          unitId: "9d5d2640-3dfe-41fb-868d-155f771aaac4",
        },
      ],
    },
    units: [
      {
        id: "9d5d2640-3dfe-41fb-868d-155f771aaac4",
        leader: false,
        modelTypes: [
          {
            invuln: 5,
            modelCount: 20,
            save: 3,
            toughness: 4,
            wounds: 2,
            id: "06bdddbd-713d-4367-8517-4605a564162c",
            name: null,
            weapons: [],
            ignore: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 4+, 5+++, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "8108d712-1df3-4487-bbbf-40a8891e9fd8",
          modelTypeName: null,
          unitId: "ec4e611b-e4b9-43a8-8cc6-ca1764848081",
        },
      ],
    },
    units: [
      {
        id: "ec4e611b-e4b9-43a8-8cc6-ca1764848081",
        leader: false,
        modelTypes: [
          {
            ignore: 5,
            modelCount: 20,
            save: 4,
            toughness: 4,
            wounds: 2,
            id: "8108d712-1df3-4487-bbbf-40a8891e9fd8",
            name: null,
            weapons: [],
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 3+, x10",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "9dc04047-efcb-4aed-879c-5a17a3a1a23e",
          modelTypeName: null,
          unitId: "020fc265-49d1-4a0a-9639-ab8ebdec7656",
        },
      ],
    },
    units: [
      {
        id: "020fc265-49d1-4a0a-9639-ab8ebdec7656",
        leader: false,
        modelTypes: [
          {
            modelCount: 10,
            save: 3,
            toughness: 4,
            wounds: 2,
            id: "9dc04047-efcb-4aed-879c-5a17a3a1a23e",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 2+, x10",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 10,
      roles: ["Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "fa22ef99-f14c-4aca-ab1f-6120580e198c",
          modelTypeName: null,
          unitId: "f2a10bfb-a23f-4dd3-8f0d-1e7632190f80",
        },
      ],
    },
    units: [
      {
        id: "f2a10bfb-a23f-4dd3-8f0d-1e7632190f80",
        leader: false,
        modelTypes: [
          {
            modelCount: 10,
            save: 2,
            toughness: 4,
            wounds: 2,
            id: "fa22ef99-f14c-4aca-ab1f-6120580e198c",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W2, 3+, x5",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 5,
      roles: ["Defender"],
      wounds: 3,
      toughness: 5,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "287e54d3-0873-4166-81fa-329e7bae02e5",
          modelTypeName: null,
          unitId: "0b66ac88-9974-45a1-ac1e-796155b7863c",
        },
      ],
    },
    units: [
      {
        id: "0b66ac88-9974-45a1-ac1e-796155b7863c",
        leader: false,
        modelTypes: [
          {
            modelCount: 5,
            save: 3,
            toughness: 5,
            wounds: 3,
            id: "287e54d3-0873-4166-81fa-329e7bae02e5",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T3, W1, 4+, x5",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 5,
      roles: ["Defender"],
      wounds: 1,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "b5156d20-3121-4526-9634-8030251a469a",
          modelTypeName: null,
          unitId: "3d6b4ef8-7c7b-4b1d-8768-a03a92fd4430",
        },
      ],
    },
    units: [
      {
        id: "3d6b4ef8-7c7b-4b1d-8768-a03a92fd4430",
        leader: false,
        modelTypes: [
          {
            modelCount: 5,
            save: 4,
            toughness: 3,
            wounds: 1,
            id: "b5156d20-3121-4526-9634-8030251a469a",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Light vehicle",
    abilitiesSelected: [],
    computed: {
      keywords: ["VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 6,
      toughness: 6,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "9702b3ff-bf02-450c-a6ac-992f629823f7",
          modelTypeName: null,
          unitId: "89ce43cb-07af-41eb-9402-f855aa23beb5",
        },
      ],
    },
    units: [
      {
        id: "89ce43cb-07af-41eb-9402-f855aa23beb5",
        leader: false,
        modelTypes: [
          {
            keywords: ["VEHICLE"],
            modelCount: 1,
            save: 4,
            toughness: 6,
            wounds: 6,
            id: "9702b3ff-bf02-450c-a6ac-992f629823f7",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Monster equivalent of light vehicle",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 6,
      toughness: 6,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "d98fa1cb-5fec-4e67-ae9e-70e750874fde",
          modelTypeName: null,
          unitId: "d915d71d-d43a-49cb-a0a9-e9a4273c04fb",
        },
      ],
    },
    units: [
      {
        id: "d915d71d-d43a-49cb-a0a9-e9a4273c04fb",
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 4,
            toughness: 6,
            wounds: 6,
            id: "d98fa1cb-5fec-4e67-ae9e-70e750874fde",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Regular vehicle",
    abilitiesSelected: [],
    computed: {
      keywords: ["VEHICLE"],
      leaders: [],
      modelCount: 1,
      points: 160,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "1a810d9d-b0b6-4858-9667-33ac3a3940c4",
          modelTypeName: null,
          unitId: "aaa1ed4e-713c-4682-91a1-d3631e88bf7e",
        },
      ],
    },
    units: [
      {
        id: "aaa1ed4e-713c-4682-91a1-d3631e88bf7e",
        leader: false,
        modelTypes: [
          {
            keywords: ["VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: "1a810d9d-b0b6-4858-9667-33ac3a3940c4",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: 160,
        abilities: [],
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Monster equivalent of regular vehicle",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "cada9a35-ad94-4c10-9817-5960f0c8b69a",
          modelTypeName: null,
          unitId: "ecfef759-53e5-4e45-a077-f8cf1d2be7d6",
        },
      ],
    },
    units: [
      {
        id: "ecfef759-53e5-4e45-a077-f8cf1d2be7d6",
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: "cada9a35-ad94-4c10-9817-5960f0c8b69a",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Vehicle with FLY",
    abilitiesSelected: [],
    computed: {
      keywords: ["FLY", "VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "672f009e-a063-459c-b3d3-ecb817799b0c",
          modelTypeName: null,
          unitId: "4c45c27f-c2a5-4958-9054-9753a435b95e",
        },
      ],
    },
    units: [
      {
        id: "4c45c27f-c2a5-4958-9054-9753a435b95e",
        leader: false,
        modelTypes: [
          {
            keywords: ["FLY", "VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: "672f009e-a063-459c-b3d3-ecb817799b0c",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Vehicle with AIRCRAFT",
    abilitiesSelected: [],
    computed: {
      keywords: ["AIRCRAFT", "FLY", "VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 10,
      toughness: 7,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "ddb29587-e7db-422d-9a78-ca76365929b1",
          modelTypeName: null,
          unitId: "a3acc364-0754-4bed-af7c-3b357ccfa9a2",
        },
      ],
    },
    units: [
      {
        id: "a3acc364-0754-4bed-af7c-3b357ccfa9a2",
        leader: false,
        modelTypes: [
          {
            keywords: ["AIRCRAFT", "FLY", "VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 7,
            wounds: 10,
            id: "ddb29587-e7db-422d-9a78-ca76365929b1",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T3, W1, 6+, 5++, 5+++ x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 1,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "0bd01399-1c21-4f9f-92a4-dafe8d6aea14",
          modelTypeName: null,
          unitId: "5f9d7c98-4c86-4d1a-bb06-a41bf7238ff5",
        },
      ],
    },
    units: [
      {
        id: "5f9d7c98-4c86-4d1a-bb06-a41bf7238ff5",
        leader: false,
        modelTypes: [
          {
            ignore: 5,
            invuln: 5,
            modelCount: 20,
            save: 6,
            toughness: 3,
            wounds: 1,
            id: "0bd01399-1c21-4f9f-92a4-dafe8d6aea14",
            name: null,
            weapons: [],
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T3, W3, 6+, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 3,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "a4b80d1b-0727-462f-a980-25b17294513a",
          modelTypeName: null,
          unitId: "c6f5ea31-c167-4cb4-b29c-3e33b74cd8a4",
        },
      ],
    },
    units: [
      {
        id: "c6f5ea31-c167-4cb4-b29c-3e33b74cd8a4",
        leader: false,
        modelTypes: [
          {
            modelCount: 20,
            save: 6,
            toughness: 3,
            wounds: 3,
            id: "a4b80d1b-0727-462f-a980-25b17294513a",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T4, W1, 6+, 3++, x20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 20,
      roles: ["Defender"],
      wounds: 1,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "2c910e24-d889-4047-b43e-bfffab3eba91",
          modelTypeName: null,
          unitId: "a774751c-e32e-4a52-844f-18425de71e78",
        },
      ],
    },
    units: [
      {
        id: "a774751c-e32e-4a52-844f-18425de71e78",
        leader: false,
        modelTypes: [
          {
            invuln: 3,
            modelCount: 20,
            save: 6,
            toughness: 4,
            wounds: 1,
            id: "2c910e24-d889-4047-b43e-bfffab3eba91",
            name: null,
            weapons: [],
            ignore: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Melee WS2 various hit re-rolls",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "b6b08d9d-4921-4109-bb0d-fe05006b2d4e",
          modelTypeName: null,
          unitId: "bf79f0e0-6e39-4712-8729-6945ef034e38",
        },
      ],
    },
    units: [
      {
        id: "bf79f0e0-6e39-4712-8729-6945ef034e38",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Sword (hits rr all possible failures)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed hit rolls",
                    scope: ["weapon"],
                    id: "4c9b8a79-820d-4fb6-bd11-772a7601101e",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "3e6efccc-f781-4064-bd0d-7de37c603941",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr all possible failures)",
              },
              {
                name: "Sword (hits rr 1-2)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all hit roll results of 1–2",
                    scope: ["weapon"],
                    id: "513fcf14-8857-49e7-bb70-de5f0f745663",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 2],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "becde3fb-3c03-494c-a449-9a68b4ccca9b",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr 1-2)",
              },
              {
                name: "Sword (hits rr all failed)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed hit rolls",
                    scope: ["weapon"],
                    id: "afb0b847-2e9c-473d-b2b2-4f1ca7c8e728",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "7efd3063-4f1f-48cb-8d13-4a26f087be69",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr all failed)",
              },
              {
                name: "Sword (hits rr 1s)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all hit roll results of 1",
                    scope: ["weapon"],
                    id: "873cd541-d56a-431c-a7cc-07d8a72c3040",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: 1,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "9a4ece4c-92be-40f8-8c0b-8cba95b56328",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (hits rr 1s)",
              },
            ],
            id: "b6b08d9d-4921-4109-bb0d-fe05006b2d4e",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "3e6efccc-f781-4064-bd0d-7de37c603941",
        "becde3fb-3c03-494c-a449-9a68b4ccca9b",
        "7efd3063-4f1f-48cb-8d13-4a26f087be69",
        "9a4ece4c-92be-40f8-8c0b-8cba95b56328",
      ],
      ranged: [],
    },
  },
  {
    name: "A: Melee WS2 various wound re-rolls",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "de203fc1-aad8-4146-b435-4bac21594ae2",
          modelTypeName: null,
          unitId: "fe271c70-94e3-40ed-b368-f2e7c1e467bc",
        },
      ],
    },
    units: [
      {
        id: "fe271c70-94e3-40ed-b368-f2e7c1e467bc",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Sword (wounds rr all possible failures)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed wound rolls",
                    scope: ["weapon"],
                    id: "baee1244-c01a-4d52-973e-92b5067d9846",
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [
                      {
                        name: "Twin-linked",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "35385c6b-7df4-4e32-b73a-e5951e739e04",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr all possible failures)",
              },
              {
                name: "Sword (wounds rr 1-2)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all wound roll results of 1–2",
                    scope: ["weapon"],
                    id: "bd043206-9988-4347-a6f4-2de1a0238dec",
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 2],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "6a56237a-24c5-41fc-a161-96b68ccba48d",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr 1-2)",
              },
              {
                name: "Sword (wounds rr all failed)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all possible failed wound rolls",
                    scope: ["weapon"],
                    id: "4a633088-d283-4970-a74e-6ec189835f4b",
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "69c2232a-c53d-4332-ae0d-355cf078234d",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr all failed)",
              },
              {
                name: "Sword (wounds rr 1s)",
                type: "Melee",
                attacks: 6,
                strength: 8,
                ap: -4,
                damage: 3,
                abilities: [
                  {
                    description: "Re-roll all wound roll results of 1",
                    scope: ["weapon"],
                    id: "3e6467b5-38de-49a1-8bfd-9d6891fbd8d8",
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "None",
                          key: "none",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: 1,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "29a37a59-57db-430e-a292-84b02606992b",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Sword (wounds rr 1s)",
              },
            ],
            id: "de203fc1-aad8-4146-b435-4bac21594ae2",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "35385c6b-7df4-4e32-b73a-e5951e739e04",
        "6a56237a-24c5-41fc-a161-96b68ccba48d",
        "69c2232a-c53d-4332-ae0d-355cf078234d",
        "29a37a59-57db-430e-a292-84b02606992b",
      ],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability D3 MWs on 6s to wound",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "0809514e-ef92-423e-be66-a659ca13ade8",
          modelTypeName: null,
          unitId: "7d7d42fd-d3b8-4c84-b0f2-7c377b22fc34",
        },
      ],
    },
    units: [
      {
        id: "7d7d42fd-d3b8-4c84-b0f2-7c377b22fc34",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Weapon with ability",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 20,
                strength: 5,
                ap: -3,
                damage: 1,
                abilities: [
                  {
                    description:
                      "Wound roll of 6 (unmodified) : D3 mortal wounds",
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Exact",
                            key: "exact",
                          },
                          rollValue: "6",
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                    ],
                    effect: {
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        mortalWoundsValue: "D3",
                        matchWeaponDamage: false,
                      },
                      text: "Mortal wounds",
                      type: "mortalWounds",
                    },
                    scope: ["weapon"],
                    id: "2d946822-e5a4-410f-9227-8491e4ec730f",
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "dc0d5b35-434d-41b8-ae0a-213256dd195d",
                bs: 2,
                ws: null,
                nameUnique: "Weapon with ability",
              },
            ],
            id: "0809514e-ef92-423e-be66-a659ca13ade8",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["dc0d5b35-434d-41b8-ae0a-213256dd195d"],
    },
  },
  {
    name: "A: Melee WS4 S4 A20",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "e1ee6256-818b-4ec0-8165-55fa579db22a",
          modelTypeName: null,
          unitId: "8ea8cb00-6587-488f-91c4-a7a5a8ed41d9",
        },
      ],
    },
    units: [
      {
        id: "8ea8cb00-6587-488f-91c4-a7a5a8ed41d9",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "ff38b371-7743-4e3e-ab9a-c532f0aa0255",
                countPerUnit: 10,
                bs: null,
                ws: 4,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
            ],
            id: "e1ee6256-818b-4ec0-8165-55fa579db22a",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["ff38b371-7743-4e3e-ab9a-c532f0aa0255"],
      ranged: [],
    },
  },
  {
    name: "D: T8, W14, 3+, x1",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 14,
      toughness: 8,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "727ab874-083d-4b1e-b463-fe7e1f79fad1",
          modelTypeName: null,
          unitId: "27f4aa51-9618-41ff-ba23-0481526aed10",
        },
      ],
    },
    units: [
      {
        id: "27f4aa51-9618-41ff-ba23-0481526aed10",
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 3,
            toughness: 8,
            wounds: 14,
            id: "727ab874-083d-4b1e-b463-fe7e1f79fad1",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Strength characteristic test",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "c6b2b168-4958-49ce-988a-3ea06fae9c3d",
          modelTypeName: null,
          unitId: "66fb697c-e702-4f7d-9f20-2aa9abc79302",
        },
      ],
    },
    units: [
      {
        id: "66fb697c-e702-4f7d-9f20-2aa9abc79302",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "S7 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 7,
                ap: -3,
                damage: 2,
                id: "d2d4b763-0c9c-40b6-b4d7-045b87a563fe",
                bs: 2,
                ws: null,
                nameUnique: "S7 gun",
                abilities: [],
              },
              {
                name: "S8 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 8,
                ap: -3,
                damage: 2,
                id: "64fd8a03-9ade-405f-9a13-ff30a34b0445",
                bs: 2,
                ws: null,
                nameUnique: "S8 gun",
                abilities: [],
              },
              {
                name: "S User gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 7,
                ap: -3,
                damage: 2,
                id: "17a903cc-7537-4ead-a695-fbbf918e74d7",
                bs: 2,
                ws: null,
                nameUnique: "S User gun",
                abilities: [],
              },
              {
                name: "S +1 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 8,
                ap: -3,
                damage: 2,
                id: "79d4c53d-58b8-49ff-90bf-2e24a6cfe746",
                bs: 2,
                ws: null,
                nameUnique: "S +1 gun",
                abilities: [],
              },
              {
                name: "Axe S User",
                type: "Melee",
                attacks: 12,
                strength: 7,
                ap: -3,
                damage: 2,
                id: "687fc393-6dca-4e4b-9ff8-9c593dc6d646",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Axe S User",
                abilities: [],
              },
              {
                name: "Axe S +1",
                type: "Melee",
                attacks: 12,
                strength: 8,
                ap: -3,
                damage: 2,
                id: "450a3159-24f0-4591-a604-fdd48fecf6c4",
                countPerUnit: 1,
                bs: null,
                ws: 2,
                nameUnique: "Axe S +1",
                abilities: [],
              },
            ],
            id: "c6b2b168-4958-49ce-988a-3ea06fae9c3d",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "687fc393-6dca-4e4b-9ff8-9c593dc6d646",
        "450a3159-24f0-4591-a604-fdd48fecf6c4",
      ],
      ranged: [
        "d2d4b763-0c9c-40b6-b4d7-045b87a563fe",
        "64fd8a03-9ade-405f-9a13-ff30a34b0445",
        "17a903cc-7537-4ead-a695-fbbf918e74d7",
        "79d4c53d-58b8-49ff-90bf-2e24a6cfe746",
      ],
    },
  },
  {
    name: "A: AP to 0 characteristic test",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "d8d85143-fa31-4dd4-a451-ed405faa247e",
          modelTypeName: null,
          unitId: "d3703647-a129-474f-9a09-d3e7ed3aa25a",
        },
      ],
    },
    units: [
      {
        id: "d3703647-a129-474f-9a09-d3e7ed3aa25a",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "AP 0 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "00ded321-d0ea-417d-9d1e-9245c3210d14",
                bs: 2,
                ws: null,
                nameUnique: "AP 0 gun",
                abilities: [],
              },
              {
                name: "AP -1 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: -1,
                damage: 1,
                id: "2fc52f47-11b5-46e4-be68-c3ebee2dec60",
                bs: 2,
                ws: null,
                nameUnique: "AP -1 gun",
                abilities: [],
              },
              {
                name: "AP -2 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: -2,
                damage: 1,
                id: "b3e5618a-4b6b-489d-a860-c6a0a09d9028",
                bs: 2,
                ws: null,
                nameUnique: "AP -2 gun",
                abilities: [],
              },
              {
                name: "AP -3 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 4,
                ap: -3,
                damage: 1,
                id: "71ba8a0d-1005-4f3f-a1fe-d379081d61a8",
                bs: 2,
                ws: null,
                nameUnique: "AP -3 gun",
                abilities: [],
              },
            ],
            id: "d8d85143-fa31-4dd4-a451-ed405faa247e",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "00ded321-d0ea-417d-9d1e-9245c3210d14",
        "2fc52f47-11b5-46e4-be68-c3ebee2dec60",
        "b3e5618a-4b6b-489d-a860-c6a0a09d9028",
        "71ba8a0d-1005-4f3f-a1fe-d379081d61a8",
      ],
    },
  },
  {
    name: "A: Damage characteristic test",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "97586f7e-44a2-4dcd-89cf-48f6ca57a350",
          modelTypeName: null,
          unitId: "7f80a6fd-c1b6-4859-969a-d7a914f927c3",
        },
      ],
    },
    units: [
      {
        id: "7f80a6fd-c1b6-4859-969a-d7a914f927c3",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Damage 1 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 6,
                ap: 0,
                damage: 1,
                id: "1b4adc60-7887-4905-a6fb-ea81cd94266a",
                bs: 2,
                ws: null,
                nameUnique: "Damage 1 gun",
                abilities: [],
              },
              {
                name: "Damage 2 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 6,
                ap: 0,
                damage: 2,
                id: "3a745289-0266-4d81-8a67-856b092e93f2",
                bs: 2,
                ws: null,
                nameUnique: "Damage 2 gun",
                abilities: [],
              },
              {
                name: "Damage D3 gun",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 12,
                strength: 6,
                ap: 0,
                damage: "D3",
                id: "ca5d80a3-19dc-4820-841d-fb0e609ff17e",
                bs: 2,
                ws: null,
                nameUnique: "Damage D3 gun",
                abilities: [],
              },
            ],
            id: "97586f7e-44a2-4dcd-89cf-48f6ca57a350",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "1b4adc60-7887-4905-a6fb-ea81cd94266a",
        "3a745289-0266-4d81-8a67-856b092e93f2",
        "ca5d80a3-19dc-4820-841d-fb0e609ff17e",
      ],
    },
  },
  {
    name: "A: Bolt pistol, BS2",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "b5b3e9c2-3e98-4666-8a5c-49f11e201b64",
          modelTypeName: null,
          unitId: "828e19a8-0fec-4a55-ac88-d1482a10cddc",
        },
      ],
    },
    units: [
      {
        id: "828e19a8-0fec-4a55-ac88-d1482a10cddc",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Bolt pistol",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "8358584c-d811-4d62-bab6-421350c293f5",
                bs: 2,
                ws: null,
                nameUnique: "Bolt pistol",
                abilities: [],
              },
            ],
            id: "b5b3e9c2-3e98-4666-8a5c-49f11e201b64",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["8358584c-d811-4d62-bab6-421350c293f5"],
    },
  },
  {
    name: "D: T3, W1, 7+, x10",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 10,
      points: 150,
      roles: ["Defender"],
      wounds: 1,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "8a1afa3d-face-4fc8-8235-8cad85a2e90a",
          modelTypeName: null,
          unitId: "b274e51e-843e-436e-af08-0bc282a825ae",
        },
      ],
    },
    units: [
      {
        id: "b274e51e-843e-436e-af08-0bc282a825ae",
        leader: false,
        modelTypes: [
          {
            modelCount: 10,
            save: 7,
            toughness: 3,
            wounds: 1,
            id: "8a1afa3d-face-4fc8-8235-8cad85a2e90a",
            name: null,
            weapons: [],
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: 150,
        abilities: [],
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "Modify (absolute) profile abilities",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 1,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "67d2db71-8ce0-4d9d-a961-1310a46b7d9a",
          modelTypeName: null,
          unitId: "50e1f147-6e42-4ec0-b798-ca283704ddef",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "Set BS to 3 (if attacker)",
            scope: ["profile"],
            id: "befd36ab-540c-4266-b1c2-47b3b616e485",
            effect: {
              data: {
                application: {
                  title: "BS",
                  key: "bs",
                },
                operator: {
                  title: "Replace",
                  key: "replace",
                },
                absoluteValue: "3",
              },
              text: "Modify (absolute)",
              type: "modifyAbsolute",
            },
            aliases: [],
            aliasActive: true,
            sharedWithAllUnits: false,
          },
          {
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "Set WS to 3 (if attacker)",
            scope: ["profile"],
            id: "a97d7355-3b55-47fc-b89f-47e89668349b",
            effect: {
              data: {
                application: {
                  title: "WS",
                  key: "ws",
                },
                operator: {
                  title: "Replace",
                  key: "replace",
                },
                absoluteValue: "3",
              },
              text: "Modify (absolute)",
              type: "modifyAbsolute",
            },
            aliases: [],
            aliasActive: true,
            sharedWithAllUnits: false,
          },
          {
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "Set save roll to 2 (if defender)",
            scope: ["profile"],
            id: "8abefdb8-8c0f-4307-bb47-43bba24c1008",
            effect: {
              data: {
                application: {
                  title: "Save roll",
                  key: "saveRoll",
                },
                operator: {
                  title: "Replace",
                  key: "replace",
                },
                absoluteValue: "2",
              },
              text: "Modify (absolute)",
              type: "modifyAbsolute",
            },
            aliases: [],
            aliasActive: true,
            sharedWithAllUnits: false,
          },
        ],
        id: "50e1f147-6e42-4ec0-b798-ca283704ddef",
        leader: false,
        modelTypes: [
          {
            modelCount: 1,
            save: 3,
            toughness: 4,
            weapons: [
              {
                name: "Bolter",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "8ab67864-3fb7-44c2-8955-6f0aac501e6c",
                bs: 4,
                ws: null,
                nameUnique: "Bolter",
                abilities: [],
              },
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "3ca7701e-e286-4a0c-9c8e-4827406f1552",
                countPerUnit: 1,
                bs: null,
                ws: 4,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
            ],
            wounds: 1,
            id: "67d2db71-8ce0-4d9d-a961-1310a46b7d9a",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["3ca7701e-e286-4a0c-9c8e-4827406f1552"],
      ranged: ["8ab67864-3fb7-44c2-8955-6f0aac501e6c"],
    },
  },
  {
    name: "A: Bolter & CC weapon (same profiles)",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      modelCount: 4,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      wounds: 4,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "94972265-3100-400b-b18b-2319a0f9ba90",
          modelTypeName: null,
          unitId: "368bc795-2444-4cfa-a289-caa530fdbb32",
        },
      ],
    },
    units: [
      {
        id: "368bc795-2444-4cfa-a289-caa530fdbb32",
        leader: false,
        modelTypes: [
          {
            modelCount: 4,
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Bolter",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "61d4d2d1-85ac-4635-9560-64f9ed6e6d0c",
                bs: 3,
                ws: null,
                nameUnique: "Bolter",
                abilities: [],
              },
              {
                name: "Close combat weapon",
                type: "Melee",
                attacks: 10,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "cd5f7b35-a61a-49bf-ad5c-142e6bbe7a8b",
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
            ],
            wounds: 4,
            id: "94972265-3100-400b-b18b-2319a0f9ba90",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: ["cd5f7b35-a61a-49bf-ad5c-142e6bbe7a8b"],
      ranged: ["61d4d2d1-85ac-4635-9560-64f9ed6e6d0c"],
    },
  },
  {
    name: "A: Squat guns",
    abilitiesSelected: [],
    computed: {
      keywords: [],
      leaders: [],
      modelCount: 0,
      roles: ["Attacker: Ranged"],
      toughness: 0,
      wounds: 0,
      points: 0,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "df85089c-4f72-42d6-a2da-69f8a1a8ed5b",
          modelTypeName: "A: Squat guns",
          unitId: "e0784fe4-69dc-4ba3-a36d-ce22bfad999f",
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: "e0784fe4-69dc-4ba3-a36d-ce22bfad999f",
        leader: false,
        modelTypes: [
          {
            id: "df85089c-4f72-42d6-a2da-69f8a1a8ed5b",
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            name: null,
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Magna-rail baseline",
                countPerUnit: "4",
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 9,
                ap: -4,
                damage: 5,
                id: "d1ad048f-fe05-49f8-99c4-17502b6c141d",
                nameUnique: "Magna-rail baseline",
                abilities: [],
              },
              {
                name: "Magna-rail",
                countPerUnit: "4",
                type: "Ranged",
                attacks: 2,
                bs: 3,
                strength: 9,
                ap: -4,
                damage: 5,
                abilities: [
                  {
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description: "Critical wound : disable save (inc. invuln)",
                    effect: {
                      text: "Disable mechanic",
                      type: "disableAbility",
                      data: {
                        option: {
                          title: "Disable save (inc. invuln)",
                          key: "disableSave",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "fb3169f7-7f8a-4825-a357-1c88e779610a",
                    aliases: [
                      {
                        name: "Devastating wounds",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "258e4136-b975-424d-9a4d-ea812f6df782",
                nameUnique: "Magna-rail",
              },
              {
                name: "Conversion Beamer",
                countPerUnit: "1",
                type: "Ranged",
                attacks: 3,
                strength: 8,
                ap: -3,
                damage: 4,
                abilities: [
                  {
                    conditions: [
                      {
                        data: {
                          attackStep: {
                            title: "Hit roll",
                            key: "hitRoll",
                          },
                          rollReq: {
                            title: "Successful roll",
                            key: "successfulRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: null,
                        },
                        preselected: false,
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        required: true,
                      },
                      {
                        data: {
                          range: {
                            title: "Over half range",
                            key: "overHalfRange",
                          },
                        },
                        preselected: false,
                        text: "Range",
                        type: "range",
                        required: false,
                      },
                    ],
                    description:
                      "Successful hit roll : 1 extra hit (over half range)",
                    effect: {
                      data: {
                        option: {
                          title: "Extra hits",
                          key: "extraHits",
                        },
                        extrasValue: 1,
                      },
                      text: "Generate extras",
                      type: "generateExtras",
                    },
                    scope: ["weapon"],
                    id: "d4a40790-35ed-4f3c-a4a4-f906a15fc4df",
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "25ef40c4-a221-4df1-a8b2-bd48f6f5d3f9",
                bs: 3,
                ws: null,
                nameUnique: "Conversion Beamer",
              },
            ],
            wounds: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: Knight",
    abilitiesSelected: [],
    computed: {
      keywords: ["TITANIC", "VEHICLE"],
      leaders: [],
      modelCount: 1,
      roles: ["Defender"],
      wounds: 24,
      toughness: 8,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "5554b977-08c1-4e2c-ac99-2ac8ba424726",
          modelTypeName: null,
          unitId: "111af0ec-aa19-48a8-8128-21c40d8c1a14",
        },
      ],
    },
    units: [
      {
        id: "111af0ec-aa19-48a8-8128-21c40d8c1a14",
        leader: false,
        modelTypes: [
          {
            invuln: 5,
            keywords: ["TITANIC", "VEHICLE"],
            modelCount: 1,
            save: 3,
            toughness: 8,
            wounds: 24,
            id: "5554b977-08c1-4e2c-ac99-2ac8ba424726",
            name: null,
            weapons: [],
            ignore: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability: Single-use hit re-rolls",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "43ac1703-c825-4622-82b7-b984b14a57aa",
          modelTypeName: null,
          unitId: "1742f428-d096-4881-9045-5af8e24f59cc",
        },
      ],
    },
    units: [
      {
        id: "1742f428-d096-4881-9045-5af8e24f59cc",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "7171e8bc-c6a2-42f2-b5af-7cdfaa530dac",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "With Hit roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed hit roll",
                    id: "3f0df4a4-e217-4490-9871-793ca256f569",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "c42de207-f21a-43c6-9439-924afddc32c4",
                bs: 3,
                ws: null,
                nameUnique: "With Hit roll : re-roll one failed",
              },
              {
                name: "With x2 Hit roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed hit roll",
                    id: "ba7e8f3d-44a0-48db-91c1-5e2c806a2c03",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "Re-roll one possible failed hit roll",
                    scope: ["weapon"],
                    id: "10bc1485-2855-42db-ba38-c1fae139d9ca",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "aba110cf-051f-4e1a-b459-525d69b896ba",
                bs: 3,
                ws: null,
                nameUnique: "With x2 Hit roll : re-roll one failed",
              },
              {
                name: "With x3 Hit roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed hit roll",
                    id: "94224f17-ce3b-4062-b8c2-21548b25fbe1",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "Re-roll one possible failed hit roll",
                    scope: ["weapon"],
                    id: "5da54a04-9b86-4805-a283-8cb34ccd329d",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                  {
                    description: "Re-roll one possible failed hit roll",
                    scope: ["weapon"],
                    id: "3ebe5bcc-60de-4ccf-99eb-7df33544b959",
                    effect: {
                      data: {
                        application: {
                          title: "Hit roll",
                          key: "hitRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "d14f2133-b096-4941-bea6-7e479d9fb595",
                bs: 3,
                ws: null,
                nameUnique: "With x3 Hit roll : re-roll one failed",
              },
            ],
            id: "43ac1703-c825-4622-82b7-b984b14a57aa",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability: Single-use wound re-rolls",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "686340a7-b8bd-4c69-8d22-f5d90431932c",
          modelTypeName: null,
          unitId: "82d39673-a647-4007-9420-93e318ab0b7a",
        },
      ],
    },
    units: [
      {
        id: "82d39673-a647-4007-9420-93e318ab0b7a",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "6c7e53b1-7d7b-4bff-80b7-041eed5ea6e3",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "With Wound roll : re-roll one failed",
                countPerUnit: 10,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    description: "Re-roll one possible failed wound roll",
                    id: "195a8757-89d9-414a-8970-c409860bb03d",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Wound roll",
                          key: "woundRoll",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "All possible failures",
                          key: "allPossibleFailures",
                        },
                        valueRange: [],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "c03b60a3-fc3c-4f6b-a670-9e9f5bae4fa5",
                bs: 3,
                ws: null,
                nameUnique: "With Wound roll : re-roll one failed",
              },
            ],
            id: "686340a7-b8bd-4c69-8d22-f5d90431932c",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Weapon ability: Single-use damage re-rolls",
    abilitiesSelected: [],
    computed: {
      leaders: [],
      roles: ["Attacker: Ranged"],
      toughness: null,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "2bb66002-e191-4c9f-8eb1-4a44958a8383",
          modelTypeName: null,
          unitId: "8c58625b-8ef6-43f5-9fbb-ba695c1ccb6b",
        },
      ],
    },
    units: [
      {
        id: "8c58625b-8ef6-43f5-9fbb-ba695c1ccb6b",
        leader: false,
        modelTypes: [
          {
            weapons: [
              {
                name: "Baseline",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                id: "1bfd44a0-bd81-4c48-a558-ce6343c35f05",
                bs: 3,
                ws: null,
                nameUnique: "Baseline",
                abilities: [],
              },
              {
                name: "Damage roll : re-roll one result of 1",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                abilities: [
                  {
                    description: "Re-roll one damage result of 1",
                    id: "24d0b593-7b8e-4285-93de-0fda5e8a21b5",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "Single",
                          key: "single",
                        },
                        valueRange: [],
                        valueSingle: "1",
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "422d4562-4344-40ff-91e8-88dc68fb38d1",
                bs: 3,
                ws: null,
                nameUnique: "Damage roll : re-roll one result of 1",
              },
              {
                name: "Damage roll : re-roll one result of 1-3",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                abilities: [
                  {
                    description: "Re-roll one damage result of 1–3",
                    id: "246d09bf-f099-42a2-9e65-7d464398995e",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 3],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "093447cb-8352-45a2-8c07-da2860cf21cc",
                bs: 3,
                ws: null,
                nameUnique: "Damage roll : re-roll one result of 1-3",
              },
              {
                name: "Damage roll : re-roll one result of 1-4",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                strength: 9,
                ap: -3,
                damage: "D3+3",
                abilities: [
                  {
                    description: "Re-roll one damage result of 1–4",
                    id: "58ef41a4-8192-43cf-9ae0-26dbd68c28de",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        application: {
                          title: "Damage",
                          key: "damage",
                        },
                        limit: {
                          title: "Single",
                          key: "single",
                        },
                        resultToReRoll: {
                          title: "Range",
                          key: "range",
                        },
                        valueRange: [1, 4],
                        valueSingle: null,
                      },
                      text: "Re-roll",
                      type: "reRoll",
                    },
                    conditions: [],
                    aliases: [],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                id: "bb98df3b-f9e0-4b73-a3ac-97f67f3a30d6",
                bs: 3,
                ws: null,
                nameUnique: "Damage roll : re-roll one result of 1-4",
              },
            ],
            id: "2bb66002-e191-4c9f-8eb1-4a44958a8383",
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        abilities: [],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "D: T7, W3, 3+, INFANTRY, x6",
    abilitiesSelected: [],
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      modelCount: 6,
      points: null,
      roles: ["Defender"],
      wounds: 3,
      toughness: 7,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "e9b4e445-5fc5-4e5b-a860-8861d7e03f6f",
          modelTypeName: null,
          unitId: "a2137fa8-7c49-4c4a-a9df-db5fbe43f794",
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: "a2137fa8-7c49-4c4a-a9df-db5fbe43f794",
        leader: false,
        modelTypes: [
          {
            bs: null,
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 6,
            save: 3,
            toughness: 7,
            weapons: [],
            wounds: 3,
            ws: null,
            id: "e9b4e445-5fc5-4e5b-a860-8861d7e03f6f",
            name: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "A: Psychic",
    abilitiesSelected: [],
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      points: null,
      roles: ["Attacker: Ranged"],
      wounds: null,
      toughness: 0,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "2669ab66-d45c-4e00-84b1-bb8af594fb31",
          modelTypeName: null,
          unitId: "5f993e93-a65d-418c-b559-826353a3d019",
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: "5f993e93-a65d-418c-b559-826353a3d019",
        leader: false,
        modelTypes: [
          {
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Mind gun",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 20,
                bs: 3,
                strength: 6,
                ap: -2,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Psychic",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [],
                    description: "Psychic",
                    effect: {
                      text: "Special",
                      type: "special",
                      data: {
                        option: {
                          title: "Psychic",
                          key: "psychic",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "a16864aa-d819-4819-9e3a-ca8c1e47f564",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "25fdbf05-7da9-43dc-950d-6efdbbfa55db",
                nameUnique: "Mind gun",
              },
              {
                name: "Baseline",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 20,
                bs: 3,
                strength: 6,
                ap: -2,
                damage: 1,
                id: "342aec86-92b9-4f57-b006-92034e9a8083",
                nameUnique: "Baseline",
                abilities: [],
              },
            ],
            wounds: null,
            id: "2669ab66-d45c-4e00-84b1-bb8af594fb31",
            name: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "25fdbf05-7da9-43dc-950d-6efdbbfa55db",
        "342aec86-92b9-4f57-b006-92034e9a8083",
      ],
    },
  },
  {
    name: "A: Lethal hits, +1 to wound & Re-roll wounds with TORRENT & non-TORRENT weapons",
    abilitiesSelected: [
      "bcd4f514-ef00-424d-899d-639c62ab4fc7",
      "2ae0aa78-dc6a-4250-8994-5ddb76f684a5",
    ],
    computed: {
      keywords: [],
      leaders: [],
      modelCount: null,
      points: null,
      roles: ["Attacker: Ranged"],
      wounds: null,
      toughness: 0,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "0790633e-3c32-4c44-95d5-49942f0fcb29",
          modelTypeName:
            "A: Lethal hits, +1 to wound & Re-roll wounds with TORRENT & non-TORRENT weapons",
          unitId: "3e66d23d-6d4a-4120-95ab-d84008e44280",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            id: "fc117c3f-93dd-4bab-8cb2-d202781bf6da",
            scope: ["profile"],
            effect: {
              data: {
                crit: false,
                type: {
                  key: "always",
                  title: "Always",
                },
                outcome: {
                  key: "wound",
                  title: "Wound",
                },
                irrespective: false,
              },
              text: "Override requirements",
              type: "overrideReqs",
            },
            aliases: [
              {
                name: "Lethal hits",
                type: "reserved",
              },
            ],
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                text: "Profile role",
                type: "profileRole",
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                data: {
                  crit: {
                    key: "hitRoll",
                    title: "Critical hit",
                  },
                },
                text: "Critical hit/wound",
                type: "crit",
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            aliasActive: true,
            description: "Critical hit : always wound (if attacker)",
            sharedWithAllUnits: false,
          },
          {
            id: "bcd4f514-ef00-424d-899d-639c62ab4fc7",
            scope: ["profile"],
            effect: {
              data: {
                operator: {
                  key: "add",
                  title: "Add",
                },
                application: {
                  key: "woundRoll",
                  title: "Wound roll",
                },
                relativeValue: "1",
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliases: [],
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                text: "Profile role",
                type: "profileRole",
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
            ],
            aliasActive: true,
            description: "+1 to wound roll (if attacker)",
            sharedWithAllUnits: false,
          },
          {
            id: "2ae0aa78-dc6a-4250-8994-5ddb76f684a5",
            scope: ["profile"],
            effect: {
              data: {
                limit: {
                  key: "none",
                  title: "No limit",
                  dataCySuffix: "none",
                },
                valueRange: [],
                application: {
                  key: "woundRoll",
                  title: "Wound roll",
                },
                valueSingle: null,
                resultToReRoll: {
                  key: "allPossibleFailures",
                  title: "All possible failures",
                  applications: [
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "all-possible-failures",
                },
              },
              text: "Re-roll",
              type: "reRoll",
            },
            aliases: [],
            conditions: [
              {
                data: {
                  profileRole: "Attacker",
                },
                text: "Profile role",
                type: "profileRole",
                scope: ["global", "profile", "weapon"],
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
            ],
            aliasActive: true,
            description:
              "Re-roll all possible failed wound rolls (if attacker)",
            sharedWithAllUnits: false,
          },
        ],
        canBeLed: false,
        canLead: false,
        id: "3e66d23d-6d4a-4120-95ab-d84008e44280",
        modelTypes: [
          {
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: null,
            name: "A: Lethal hits, +1 to wound & Re-roll wounds with TORRENT & non-TORRENT weapons",
            save: null,
            toughness: null,
            weapons: [
              {
                name: "Not TORRENT weapon",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 5,
                bs: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "c234d73e-31c0-4c4e-9cd2-d867275098ca",
                nameUnique: "Not TORRENT weapon",
                abilities: [],
              },
              {
                name: "TORRENT weapon",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 4,
                bs: "",
                strength: 4,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    id: "edf53a5f-fc50-4b74-8c03-fe7a7f766901",
                    scope: ["weapon"],
                    effect: {
                      data: {
                        crit: false,
                        type: {
                          key: "always",
                          title: "Always",
                        },
                        outcome: {
                          key: "hit",
                          title: "Hit",
                        },
                        irrespective: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliases: [
                      {
                        name: "Torrent",
                        type: "reserved",
                      },
                    ],
                    conditions: [],
                    aliasActive: true,
                    description: "Always hit",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "f0a4cc44-0fd6-4aae-9bf0-b960b0d4931d",
                nameUnique: "TORRENT weapon",
              },
            ],
            wounds: null,
            id: "0790633e-3c32-4c44-95d5-49942f0fcb29",
          },
        ],
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["c234d73e-31c0-4c4e-9cd2-d867275098ca"],
    },
  },
  {
    name: "Boys led by Medic",
    abilitiesSelected: [],
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      leaders: [
        {
          unitId: 1,
          unitName: "Medic",
        },
      ],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      modelCount: 11,
      points: 155,
      wounds: 14,
      toughness: 5,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "37066bdb-8473-4eaa-86bf-cdd1b7e7c271",
          modelTypeName: "Boys",
          unitId: "dba5df48-a22d-45ab-8502-73668049bf4d",
        },
        {
          modelTypeId: "2fa00b37-e286-45e0-9b5e-4c14d1764a1a",
          modelTypeName: "Boss Knob",
          unitId: "dba5df48-a22d-45ab-8502-73668049bf4d",
        },
        {
          modelTypeId: "228b1876-489c-4aa0-9a79-8389ef537674",
          modelTypeName: "Medic",
          unitId: "0ca77d8b-f305-41a6-802f-16c0c2ae9c76",
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: "0ca77d8b-f305-41a6-802f-16c0c2ae9c76",
        leader: true,
        modelTypes: [
          {
            id: "228b1876-489c-4aa0-9a79-8389ef537674",
            ignore: null,
            invuln: null,
            keywords: ["CHARACTER", "INFANTRY"],
            modelCount: 1,
            name: "Medic",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Syringe",
                countPerUnit: 1,
                type: "Melee",
                attacks: 1,
                ws: 3,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "94599f88-0d7c-4093-9fd9-cefd6d41153e",
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                      {
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        description: "Exclude one or more keywords.",
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description:
                      "Critical wound : D6 mortal wounds (excludes VEHICLE)",
                    effect: {
                      text: "Mortal wounds",
                      type: "mortalWounds",
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        matchWeaponDamage: false,
                        mortalWoundsValue: "D6",
                      },
                    },
                    id: "0d59ba41-696a-4468-a6f6-d3d55e13529b",
                    scope: ["weapon"],
                    sharedWithAllUnits: false,
                  },
                ],
                id: "2ff8a815-7595-4430-a0ec-0411770d1ded",
                nameUnique: "Syringe",
              },
              {
                name: "Power claw",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 4,
                strength: 9,
                ap: -2,
                damage: 2,
                id: "0c6178c7-1077-41cd-9dd0-1137d5eb1585",
                nameUnique: "Power claw",
                abilities: [],
              },
            ],
            wounds: 3,
          },
        ],
        name: "Medic",
        points: 70,
      },
      {
        abilities: [],
        id: "dba5df48-a22d-45ab-8502-73668049bf4d",
        leader: false,
        modelTypes: [
          {
            id: "37066bdb-8473-4eaa-86bf-cdd1b7e7c271",
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 9,
            name: "Boys",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Chopper",
                countPerUnit: 9,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                id: "39afb789-7da7-4115-a1c1-400de2a913ac",
                nameUnique: "Chopper",
                abilities: [],
              },
              {
                name: "Slugger",
                countPerUnit: 9,
                type: "Ranged",
                attacks: 1,
                bs: 5,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "1774e0b8-4024-4a7a-8042-7bda3fa064eb",
                nameUnique: "Slugger (Boys)",
                abilities: [],
              },
            ],
            wounds: 1,
          },
          {
            id: "2fa00b37-e286-45e0-9b5e-4c14d1764a1a",
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Boss Knob",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Big chopper",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                id: "ce338e68-16de-4f1c-92aa-b6a5404ff535",
                nameUnique: "Big chopper",
                abilities: [],
              },
              {
                name: "Slugger",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 5,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "d5f77a5c-b172-4e1d-99c1-02dca1c583b8",
                nameUnique: "Slugger (Boss Knob)",
                abilities: [],
              },
            ],
            wounds: 2,
          },
        ],
        name: "Boys",
        points: 85,
      },
    ],
    weaponsSelected: {
      melee: [
        "2ff8a815-7595-4430-a0ec-0411770d1ded",
        "0c6178c7-1077-41cd-9dd0-1137d5eb1585",
        "39afb789-7da7-4115-a1c1-400de2a913ac",
        "ce338e68-16de-4f1c-92aa-b6a5404ff535",
      ],
      ranged: [
        "1774e0b8-4024-4a7a-8042-7bda3fa064eb",
        "d5f77a5c-b172-4e1d-99c1-02dca1c583b8",
      ],
    },
  },
  {
    name: "Avengers",
    abilitiesSelected: [],
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 5,
      wounds: 6,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "6c36ad81-5fb1-443b-9bf5-abd32385c15e",
          modelTypeName: "Avenger",
          unitId: "12af5062-9133-47d7-ac0c-4faa24919985",
        },
        {
          modelTypeId: "c4bee2e0-4084-4658-9dc9-dc4a08a60a6c",
          modelTypeName: "Avenger Exarch",
          unitId: "12af5062-9133-47d7-ac0c-4faa24919985",
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: "12af5062-9133-47d7-ac0c-4faa24919985",
        leader: false,
        modelTypes: [
          {
            id: "6c36ad81-5fb1-443b-9bf5-abd32385c15e",
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 4,
            name: "Avenger",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Close combat weapon",
                countPerUnit: 4,
                type: "Melee",
                attacks: 2,
                ws: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                id: "df3773f5-9919-4e1d-8a3e-edbcf99ab3d0",
                nameUnique: "Close combat weapon",
                abilities: [],
              },
              {
                name: "Catapult",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 3,
                bs: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Lethal hits",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical hit",
                            key: "hitRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                      },
                    ],
                    description: "Critical hit : always wound",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "3063f704-9e59-41f1-889c-d37d5c094c9a",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "726b1abb-5e93-49db-acbe-a2e8e5748405",
                nameUnique: "Catapult",
              },
            ],
            wounds: 1,
          },
          {
            id: "c4bee2e0-4084-4658-9dc9-dc4a08a60a6c",
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Avenger Exarch",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Glaive",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 5,
                ap: -2,
                damage: 1,
                id: "278992aa-b443-4174-94c7-cf6e414f7904",
                nameUnique: "Glaive",
                abilities: [],
              },
              {
                name: "Pistol",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                id: "ff37599c-d17c-4210-9652-ff001624ae56",
                nameUnique: "Pistol",
                abilities: [],
              },
            ],
            wounds: 2,
          },
        ],
        name: "Avengers",
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "df3773f5-9919-4e1d-8a3e-edbcf99ab3d0",
        "278992aa-b443-4174-94c7-cf6e414f7904",
      ],
      ranged: [
        "726b1abb-5e93-49db-acbe-a2e8e5748405",
        "ff37599c-d17c-4210-9652-ff001624ae56",
      ],
    },
  },
  {
    name: "Vanguard led by Manipulus",
    abilitiesSelected: [
      "82b53305-eb9e-4181-9f3b-73ba36f7b715",
      "c973de36-82a0-47ca-b7cb-a06168f6fdee",
    ],
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      leaders: [
        {
          unitId: 2,
          unitName: "Manipulus",
        },
      ],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 11,
      wounds: 14,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "945ab9dc-c1db-41ed-8bbe-30d7526855b5",
          modelTypeName: "Manipulus",
          unitId: "3f145868-7b85-450a-9b5a-912229a1f171",
        },
        {
          modelTypeId: "9aa2ab9d-4694-47e5-8f45-c06334714429",
          modelTypeName: "Skitarii Vanguard",
          unitId: "8a79c0e1-a87a-4f91-9220-60093ff6f1a5",
        },
        {
          modelTypeId: "4c4c2a33-1074-4a22-8127-edd6d45fdd68",
          modelTypeName: "Skitarii Vanguard Alpha",
          unitId: "8a79c0e1-a87a-4f91-9220-60093ff6f1a5",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            aliases: [
              {
                name: "Ignores cover",
                type: "reserved",
              },
            ],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
            ],
            description: "Ignore cover (if attacker)",
            effect: {
              text: "Special",
              type: "special",
              data: {
                option: {
                  title: "Ignore cover",
                  key: "ignoreCover",
                },
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: true,
            id: "82b53305-eb9e-4181-9f3b-73ba36f7b715",
          },
        ],
        id: "8a79c0e1-a87a-4f91-9220-60093ff6f1a5",
        leader: false,
        modelTypes: [
          {
            id: "9aa2ab9d-4694-47e5-8f45-c06334714429",
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 9,
            name: "Skitarii Vanguard",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Radium carbine",
                countPerUnit: 9,
                type: "Ranged",
                attacks: 3,
                bs: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "9ae1375f-e426-4e6a-8fd1-a7859ca5c429",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "5feef58f-5840-4e44-8f06-ef0c9bf72243",
                nameUnique: "Radium carbine",
              },
              {
                name: "Close combat weapon",
                countPerUnit: 9,
                type: "Melee",
                attacks: 1,
                ws: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                id: "0a33e360-101d-43d4-be1d-d86c5c2ff927",
                nameUnique: "Close combat weapon",
                abilities: [],
              },
            ],
            wounds: 1,
          },
          {
            id: "4c4c2a33-1074-4a22-8127-edd6d45fdd68",
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Skitarii Vanguard Alpha",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Alpha combat weapon",
                countPerUnit: 1,
                type: "Melee",
                attacks: 2,
                ws: 4,
                strength: 5,
                ap: -1,
                damage: 1,
                id: "fa6c28bb-468e-4024-be71-ef2eef555077",
                nameUnique: "Alpha combat weapon",
                abilities: [],
              },
              {
                name: "Radium carbine",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 3,
                bs: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "4b6f0109-fef3-4ede-a57c-bf68f110b3b1",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "4da33d80-6409-4491-8f8c-4f5c6ad9ffdb",
                nameUnique: "Radium carbine",
              },
            ],
            wounds: 1,
          },
        ],
        name: "Skitarii Vanguard",
        points: null,
      },
      {
        abilities: [
          {
            aliases: [
              {
                name: "Lethal hits",
                type: "reserved",
              },
            ],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                text: "Critical hit/wound",
                type: "crit",
                data: {
                  crit: {
                    title: "Critical hit",
                    key: "hitRoll",
                  },
                },
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            description: "Critical hit : always wound (if attacker)",
            effect: {
              text: "Override requirements",
              type: "overrideReqs",
              data: {
                crit: false,
                irrespective: false,
                outcome: {
                  title: "Wound",
                  key: "wound",
                },
                type: {
                  title: "Always",
                  key: "always",
                },
              },
            },
            scope: ["profile"],
            id: "c973de36-82a0-47ca-b7cb-a06168f6fdee",
            sharedWithAllUnits: false,
          },
          {
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: true,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            description: "4++ invuln save",
            aliases: [],
            effect: {
              data: {
                invulnValue: "4",
              },
              text: "Invulnerable save",
              type: "invulnSave",
            },
            scope: ["profile"],
            aliasActive: true,
            id: "7e147699-686e-4018-96da-0ac7467da863",
            sharedWithAllUnits: false,
          },
        ],
        id: "3f145868-7b85-450a-9b5a-912229a1f171",
        leader: true,
        modelTypes: [
          {
            id: "945ab9dc-c1db-41ed-8bbe-30d7526855b5",
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY", "CHARACTER"],
            modelCount: 1,
            name: "Manipulus",
            save: 2,
            toughness: 4,
            weapons: [
              {
                name: "Omnissian staff",
                countPerUnit: 1,
                type: "Melee",
                attacks: 4,
                ws: 3,
                strength: 6,
                ap: -1,
                damage: 2,
                id: "085b3ab7-7d41-4a24-afda-8533eb5588c9",
                nameUnique: "Omnissian staff",
                abilities: [],
              },
              {
                name: "Transonic cannon",
                countPerUnit: 1,
                type: "Ranged",
                attacks: "D6",
                strength: 4,
                ap: 0,
                damage: 2,
                abilities: [
                  {
                    description: "Always hit",
                    scope: ["weapon"],
                    conditions: [],
                    aliases: [
                      {
                        name: "Torrent",
                        type: "reserved",
                      },
                    ],
                    effect: {
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Hit",
                          key: "hit",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                        crit: false,
                      },
                      text: "Override requirements",
                      type: "overrideReqs",
                    },
                    aliasActive: true,
                    id: "a2e1eb1b-dc2c-437e-9ada-cb3916973bc0",
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [
                      {
                        name: "Devastating wounds",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description: "Critical wound : disable save (inc. invuln)",
                    effect: {
                      text: "Disable mechanic",
                      type: "disableAbility",
                      data: {
                        option: {
                          title: "Disable save (inc. invuln)",
                          key: "disableSave",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "a7c424c5-49f1-43fb-b82b-a5896d2f7b4c",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "63107cdd-ae66-4040-85fc-144826ae1cb4",
                nameUnique: "Transonic cannon",
              },
            ],
            wounds: 4,
          },
        ],
        name: "Manipulus",
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "0a33e360-101d-43d4-be1d-d86c5c2ff927",
        "fa6c28bb-468e-4024-be71-ef2eef555077",
        "085b3ab7-7d41-4a24-afda-8533eb5588c9",
      ],
      ranged: [
        "5feef58f-5840-4e44-8f06-ef0c9bf72243",
        "4da33d80-6409-4491-8f8c-4f5c6ad9ffdb",
        "63107cdd-ae66-4040-85fc-144826ae1cb4",
      ],
    },
  },
  {
    name: "Breachers led by Dominus",
    abilitiesSelected: ["544c6fca-cedc-4a86-9584-8cba2598107f"],
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      leaders: [
        {
          unitId: 2,
          unitName: "Dominus",
        },
      ],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 4,
      wounds: 13,
      toughness: 7,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "a9b5bf92-8396-419d-a9ba-0a72f111a31d",
          modelTypeName: "Breacher",
          unitId: "fb3795c1-7f0c-40a9-a1dc-26af2535799f",
        },
        {
          modelTypeId: "ed84838f-defd-4338-b67d-4b8fd57bf261",
          modelTypeName: "Dominus",
          unitId: "7345281b-cec3-478a-9ac6-dd6d8a602f52",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Re-roll all hit roll results of 1 (if attacker)",
            effect: {
              text: "Re-roll",
              type: "reRoll",
              data: {
                application: {
                  title: "Hit roll",
                  key: "hitRoll",
                },
                limit: {
                  title: "No limit",
                  key: "none",
                  dataCySuffix: "none",
                },
                resultToReRoll: {
                  title: "Single result",
                  key: "single",
                  applications: [
                    "damage",
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "single",
                },
                valueRange: [],
                valueSingle: "1",
              },
            },
            scope: ["profile"],
            id: "579866db-3f6c-45a2-b055-6836465a5fe6",
            sharedWithAllUnits: false,
          },
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Re-roll all possible failed hit rolls (if attacker)",
            effect: {
              text: "Re-roll",
              type: "reRoll",
              data: {
                application: {
                  title: "Hit roll",
                  key: "hitRoll",
                },
                limit: {
                  title: "No limit",
                  key: "none",
                  dataCySuffix: "none",
                },
                resultToReRoll: {
                  title: "All possible failures",
                  key: "allPossibleFailures",
                  applications: [
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "all-possible-failures",
                },
                valueRange: [],
                valueSingle: null,
              },
            },
            scope: ["profile"],
            id: "4c10f321-9427-4603-9345-53191e351ceb",
            sharedWithAllUnits: true,
          },
        ],
        id: "fb3795c1-7f0c-40a9-a1dc-26af2535799f",
        leader: false,
        modelTypes: [
          {
            id: "a9b5bf92-8396-419d-a9ba-0a72f111a31d",
            ignore: null,
            invuln: 6,
            keywords: ["INFANTRY"],
            modelCount: 3,
            name: "Breacher",
            save: 3,
            toughness: 7,
            weapons: [
              {
                name: "Hydraulic claw",
                countPerUnit: 1,
                type: "Melee",
                attacks: 2,
                ws: 4,
                strength: 8,
                ap: -2,
                damage: 3,
                id: "db022623-6c8a-4a0f-95e5-968204937e19",
                nameUnique: "Hydraulic claw",
                abilities: [],
              },
              {
                name: "Heavy arc rifle",
                countPerUnit: 3,
                type: "Ranged",
                attacks: 2,
                bs: 4,
                strength: 8,
                ap: -2,
                damage: 3,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-VEHICLE 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (VEHICLE only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "0281c608-cf74-4640-b8e7-1554bdcb399c",
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [
                      {
                        name: "Rapid fire 2",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 2 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: "2",
                        applyToExtraAttacks: false,
                      },
                    },
                    scope: ["weapon"],
                    id: "73d6fd1b-f7a7-4f5f-9cc0-203c56d6b8e6",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "ff358933-f56a-4de0-ab33-45fad979bcdc",
                nameUnique: "Heavy arc rifle",
              },
            ],
            wounds: 3,
          },
        ],
        name: "Breachers",
        points: null,
      },
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Defender",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Feel no pain 5+",
            effect: {
              text: "Feel no pain",
              type: "feelNoPain",
              data: {
                value: "5",
              },
            },
            scope: ["profile"],
            id: "544c6fca-cedc-4a86-9584-8cba2598107f",
            sharedWithAllUnits: true,
          },
        ],
        id: "7345281b-cec3-478a-9ac6-dd6d8a602f52",
        leader: true,
        modelTypes: [
          {
            id: "ed84838f-defd-4338-b67d-4b8fd57bf261",
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY", "CHARACTER"],
            modelCount: 1,
            name: "Dominus",
            save: 2,
            toughness: 4,
            weapons: [
              {
                name: "Omnissian axe",
                countPerUnit: 1,
                type: "Melee",
                attacks: 4,
                ws: 3,
                strength: 6,
                ap: -2,
                damage: 2,
                id: "3bdc38fb-ede8-43cf-97de-5b59596adda3",
                nameUnique: "Omnissian axe",
                abilities: [],
              },
              {
                name: "Macrostubber",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 5,
                bs: 3,
                strength: 4,
                ap: 0,
                damage: 1,
                id: "3b758741-b66f-47e4-b84e-31444cf21b5f",
                nameUnique: "Macrostubber",
                abilities: [],
              },
              {
                name: "Volkite blaster",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 3,
                bs: 3,
                strength: 5,
                ap: 0,
                damage: 2,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Devastating wounds",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description: "Critical wound : disable save (inc. invuln)",
                    effect: {
                      text: "Disable mechanic",
                      type: "disableAbility",
                      data: {
                        option: {
                          title: "Disable save (inc. invuln)",
                          key: "disableSave",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: "c8e913aa-44f9-4311-8f97-b8df9adffa25",
                    sharedWithAllUnits: false,
                  },
                ],
                id: "f76430bc-a75c-4d1b-ad7b-be7bf755cabe",
                nameUnique: "Volkite blaster",
              },
            ],
            wounds: 4,
          },
        ],
        name: "Dominus",
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [
        "db022623-6c8a-4a0f-95e5-968204937e19",
        "3bdc38fb-ede8-43cf-97de-5b59596adda3",
      ],
      ranged: [
        "ff358933-f56a-4de0-ab33-45fad979bcdc",
        "3b758741-b66f-47e4-b84e-31444cf21b5f",
        "f76430bc-a75c-4d1b-ad7b-be7bf755cabe",
      ],
    },
  },
  {
    name: "Advanced attacker with shared & unshared abilities",
    abilitiesSelected: [],
    computed: {
      keywords: [],
      modelCount: 7,
      roles: ["Attacker: Ranged", "Defender"],
      toughness: 4,
      wounds: 16,
      points: 0,
      leaders: [
        {
          unitId: 1,
          unitName: "Leader unit",
        },
      ],
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "6ab5140f-a14a-42d2-9045-d57c26cdfb89",
          modelTypeName: "Grunt model type",
          unitId: "e364b42a-7fca-4c9f-b072-6570f881e2fb",
        },
        {
          modelTypeId: "ac670d2f-e48d-46d3-9005-3ef88b69691c",
          modelTypeName: "Leader model type",
          unitId: "1b69e41a-6daa-4923-b68d-213f1a5fa11a",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "+1 to wound roll (if attacker)",
            effect: {
              text: "Modify (relative)",
              type: "modifyRelative",
              data: {
                application: {
                  title: "Wound roll",
                  key: "woundRoll",
                },
                operator: {
                  title: "Add",
                  key: "add",
                },
                relativeValue: "1",
              },
            },
            id: "8f2e73e5-7f6b-468b-9cfb-14c34b975d00",
            scope: ["profile"],
            sharedWithAllUnits: false,
            updated: 1719855292765,
          },
        ],
        id: "e364b42a-7fca-4c9f-b072-6570f881e2fb",
        leader: false,
        modelTypes: [
          {
            id: "6ab5140f-a14a-42d2-9045-d57c26cdfb89",
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 6,
            name: "Grunt model type",
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Grunt gun",
                nameUnique: "Grunt gun",
                countPerUnit: 6,
                type: "Ranged",
                attacks: 2,
                bs: 4,
                strength: 4,
                ap: 0,
                damage: 2,
                updated: 1719856287678,
                id: "16c5d430-c306-4d21-a131-50d3f605084a",
                computed: {
                  modelTypeId: 2,
                  unitId: 2,
                },
                abilities: [],
              },
            ],
            wounds: 2,
          },
        ],
        name: "Grunt unit",
        points: null,
      },
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Re-roll all possible failed hit rolls (if attacker)",
            effect: {
              text: "Re-roll",
              type: "reRoll",
              data: {
                application: {
                  title: "Hit roll",
                  key: "hitRoll",
                },
                limit: {
                  title: "No limit",
                  key: "none",
                  dataCySuffix: "none",
                },
                resultToReRoll: {
                  title: "All possible failures",
                  key: "allPossibleFailures",
                  applications: [
                    "hitRoll",
                    "invulnSaveRoll",
                    "saveRoll",
                    "woundRoll",
                  ],
                  dataCySuffix: "all-possible-failures",
                },
                valueRange: [],
                valueSingle: null,
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: true,
            id: "b98e1a5c-3ad2-46bd-b2ce-76c8608df2ee",
            updated: 1719855087644,
          },
        ],
        id: "1b69e41a-6daa-4923-b68d-213f1a5fa11a",
        leader: true,
        modelTypes: [
          {
            id: "ac670d2f-e48d-46d3-9005-3ef88b69691c",
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 1,
            name: "Leader model type",
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Leader gun",
                nameUnique: "Leader gun",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 12,
                bs: 4,
                strength: 4,
                ap: 0,
                damage: 2,
                updated: 1719856295505,
                id: "87db7c1a-6801-442e-a178-05ed49f2dd86",
                computed: {
                  modelTypeId: 1,
                  unitId: 1,
                },
                abilities: [],
              },
            ],
            wounds: 4,
          },
        ],
        name: "Leader unit",
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [
        "16c5d430-c306-4d21-a131-50d3f605084a",
        "87db7c1a-6801-442e-a178-05ed49f2dd86",
      ],
    },
  },
  {
    name: "Advanced defender with shared & unshared abilities",
    abilitiesSelected: [],
    computed: {
      keywords: [],
      modelCount: 4,
      roles: ["Defender"],
      toughness: 4,
      wounds: 8,
      points: 0,
      leaders: [
        {
          unitId: 1,
          unitName: "Leader unit",
        },
      ],
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "eeafe418-2e36-4618-8f92-b5075685b7a9",
          modelTypeName: "Grunt model type",
          unitId: "041b6bce-d881-4c87-a562-a89dfcf6f713",
        },
        {
          modelTypeId: "3b717656-baa6-47e7-ae7e-4caf653f2664",
          modelTypeName: "Leader model type",
          unitId: "00fb6178-9dec-443b-b1df-8f5afb2c3af3",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            description: "Degrade AP by 3 (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "AP",
                  key: "ap",
                },
                operator: {
                  title: "Degrade by",
                  key: "degrade",
                },
                relativeValue: "3",
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: false,
            id: "84d51128-6df3-4a4b-bdfb-dc98c5e29923",
            updated: 1720109150634,
          },
        ],
        id: "041b6bce-d881-4c87-a562-a89dfcf6f713",
        leader: false,
        modelTypes: [
          {
            id: "eeafe418-2e36-4618-8f92-b5075685b7a9",
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 2,
            name: "Grunt model type",
            save: 4,
            toughness: 4,
            weapons: [],
            wounds: 2,
          },
        ],
        name: "Grunt unit",
        points: null,
      },
      {
        abilities: [
          {
            description: "-1 to damage (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "Damage",
                  key: "damage",
                },
                operator: {
                  title: "Subtract",
                  key: "subtract",
                },
                relativeValue: "1",
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: true,
            id: "2978c6e0-74f8-490a-859b-762446675723",
            updated: 1720109036837,
          },
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Defender",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Degrade AP by 4 (if defender)",
            effect: {
              text: "Modify (relative)",
              type: "modifyRelative",
              data: {
                application: {
                  title: "AP",
                  key: "ap",
                },
                operator: {
                  title: "Degrade by",
                  key: "degrade",
                },
                relativeValue: "4",
              },
            },
            id: "bcf0fe15-8510-4b11-a4a3-72e5743f4420",
            scope: ["profile"],
            sharedWithAllUnits: true,
            updated: 1720116997851,
          },
        ],
        id: "00fb6178-9dec-443b-b1df-8f5afb2c3af3",
        leader: true,
        modelTypes: [
          {
            id: "3b717656-baa6-47e7-ae7e-4caf653f2664",
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 2,
            name: "Leader model type",
            save: 4,
            toughness: 4,
            weapons: [],
            wounds: 2,
          },
        ],
        name: "Leader unit",
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "Multiple attacker abilities & multiple defender abilities",
    abilitiesSelected: [],
    computed: {
      keywords: [],
      modelCount: 4,
      roles: ["Attacker: Ranged", "Defender"],
      toughness: 4,
      wounds: 16,
      points: 0,
      leaders: [],
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: "7e0d87a1-2bd2-45b5-828e-693ca22eab95",
          modelTypeName:
            "Multiple attacker abilities & multiple defender abilities",
          unitId: "24ff4a5b-fa07-442f-a634-ce012ea441a6",
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            description: "+1 to armour save roll (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "Armour save roll",
                  key: "armourSaveRoll",
                },
                operator: {
                  title: "Add",
                  key: "add",
                },
                relativeValue: 1,
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: false,
            id: "8a72abf9-319a-497e-864d-d118903cfe59",
          },
          {
            description: "-1 to damage (if defender)",
            scope: ["profile"],
            conditions: [
              {
                data: {
                  profileRole: "Defender",
                },
                preselected: false,
                text: "Profile role",
                type: "profileRole",
              },
            ],
            aliases: [],
            effect: {
              data: {
                application: {
                  title: "Damage",
                  key: "damage",
                },
                operator: {
                  title: "Subtract",
                  key: "subtract",
                },
                relativeValue: 1,
              },
              text: "Modify (relative)",
              type: "modifyRelative",
            },
            aliasActive: true,
            sharedWithAllUnits: false,
            id: "787676b0-6d1d-4bea-9dbd-ff97d9e92d0a",
          },
          {
            aliases: [
              {
                name: "Lethal hits",
                type: "reserved",
              },
            ],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                text: "Critical hit/wound",
                type: "crit",
                data: {
                  crit: {
                    title: "Critical hit",
                    key: "hitRoll",
                  },
                },
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            description: "Critical hit : always wound (if attacker)",
            effect: {
              text: "Override requirements",
              type: "overrideReqs",
              data: {
                crit: false,
                irrespective: false,
                outcome: {
                  title: "Wound",
                  key: "wound",
                },
                type: {
                  title: "Always",
                  key: "always",
                },
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: false,
            id: "f661fd71-eefb-45ec-a8ef-b812943d524a",
          },
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Attacker",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
              },
              {
                text: "Critical hit/wound",
                type: "crit",
                data: {
                  crit: {
                    title: "Critical wound",
                    key: "woundRoll",
                  },
                },
                description: 'Require a hit or wound to be "critical".',
                preselected: false,
              },
            ],
            description: "Critical wound : improve AP by 1 (if attacker)",
            effect: {
              text: "Modify (relative)",
              type: "modifyRelative",
              data: {
                application: {
                  title: "AP",
                  key: "ap",
                },
                operator: {
                  title: "Improve by",
                  key: "improve",
                },
                relativeValue: "1",
              },
            },
            scope: ["profile"],
            sharedWithAllUnits: false,
            id: "18abb1a3-71cd-494c-84cf-749c5be9fd84",
          },
        ],
        id: "24ff4a5b-fa07-442f-a634-ce012ea441a6",
        leader: false,
        modelTypes: [
          {
            id: "7e0d87a1-2bd2-45b5-828e-693ca22eab95",
            ignore: null,
            invuln: null,
            keywords: [],
            modelCount: 4,
            name: null,
            save: 4,
            toughness: 4,
            weapons: [
              {
                name: "Gun 1",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 4,
                bs: 4,
                strength: 4,
                ap: 0,
                damage: 1,
                updated: 1720121201818,
                id: "a6aae654-0931-4290-8fea-b64f9938bf96",
                nameUnique: "Gun 1",
                computed: {
                  modelTypeId: 1,
                  unitId: 1,
                },
                abilities: [],
              },
            ],
            wounds: 4,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: ["a6aae654-0931-4290-8fea-b64f9938bf96"],
    },
  },
]
